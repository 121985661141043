console.log(`server url:  ${process.env.REACT_APP_SERVER_URL}`);

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const SERVER = `${SERVER_URL}__username__`;
// export const USER_ENDPOINT = `${API_SERVER}user`;
export const LOGIN_ENDPOINT = `${SERVER}/accounts/login/`;
export const ADD_FORM = `${SERVER}/uiformbuilder/add-form-json/`;
export const LIST_FORM = `${SERVER}/uiformbuilder/list-form-json/`;
export const UPDATE_FORM = `${SERVER}/uiformbuilder/update-form-json/`;
export const PUBLISH_FORM = `${SERVER}/uiformbuilder/publish-form-json/`;
export const REFRESH_TOKEN_ENDPOINT = '';
export const USER_MENUS_ENDPOINT = '';
export const refreshTokenURL = `msurvey/api/auth/refresh-token`;
// export const masterDataURL = `msurvey/api/form-builder/get-master-data`;
// export const saveURL = `msurvey/api/form-builder/create-json`;
export const formDefinitionURL = `msurvey/api/form-builder/get-json`;
export const fetchCategoryURL = `mSurvey/api/category/get`;
export const fetchItemURL = `mSurvey/api/master/item/get`;
export const fetchLanguageURL = `mSurvey/api/language/get`;

// New

export const metaDataUrl = `api/v1/formDesigner/listSurveyMeta`;
export const formStylesUrl = `api/v1/formDesigner/listFormStyle`;
export const hxlTagsUrl = `api/v1/formDesigner/listHxlTag`;
export const languageUrl = `api/v1/project/listLanguage`;
export const saveURL = `api/v1/formDesigner/saveSurveyForm`;
export const questionTypesUrl = `api/v1/formDesigner/listQuestionType`;
export const surveyFormByIdUrl = `api/v1/formDesigner/surveyForm`;
