import * as React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import Style from '../../features/FormDesigner/Questions/Style';
import { Form, Input } from 'antd';
import MatrixStyle from './Style';
import { _deserailize } from '../../features/FormDesigner/Settings/Options/utils';
import { IMatrixColumn, IMatrixRow, IQuestion } from '../../types/state';
import { useDispatch } from 'react-redux';
import { removeMatrixRow, updateMatrixRowLabel, updateMatrixRowPrefix } from '../../redux/QuestionSlice';
import MatrixDialog from './MatrixDialog';

type RowPropsType = {
  key: number;
  row: IMatrixRow;
  cols: IMatrixColumn[] | [];
  path: string;
};

function Row({ row, cols, path }: RowPropsType) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const classes = Style();
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ border: '1px solid #f7f7f7' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          {row.label}
          <DeleteOutlined
            className={classes.deleteIcon}
            onClick={() => dispatch(removeMatrixRow({ path: path, rowId: row.id }))}
          />
        </TableCell>
        {cols &&
          cols.map((col: any, key: any) => (
            <TableCell style={{ border: '1px solid #f7f7f7' }} key={key} component="th" scope="row">
              {cols[key].choices?.map((choice: any) => (
                <>
                  <FormControl>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel disabled value="female" control={<Radio />} label={choice.label} />
                    </RadioGroup>
                  </FormControl>
                </>
              ))}
            </TableCell>
          ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div"></Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <div>
                    <Form {...layout} form={form} name="control-hooks">
                      <Form.Item name="label" label="Label" rules={[{ required: true }]}>
                        <Input
                          onChange={(e) =>
                            dispatch(updateMatrixRowLabel({ path: path, rowId: row.id, newLabel: e.target.value }))
                          }
                        />
                      </Form.Item>
                      <Form.Item name="dataColumnPrefix" label="Data Column Prefix" rules={[{ required: false }]}>
                        <Input
                          onChange={(e) =>
                            dispatch(updateMatrixRowPrefix({ path: path, rowId: row.id, prefix: e.target.value }))
                          }
                        />
                      </Form.Item>
                    </Form>
                  </div>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function MatrixTable(props: { question: IQuestion; deleteColumn: (value: string) => void }) {
  const { question, deleteColumn } = props;
  const [selectedColumn, setSelectedColumn] = React.useState<IMatrixColumn>();
  const [columnModalOpen, setColumnModalOpen] = React.useState(false);

  const classes = Style();
  const matrixClasses = MatrixStyle();

  return (
    <>
      {selectedColumn && (
        <MatrixDialog
          open={columnModalOpen}
          onClose={() => setColumnModalOpen(false)}
          column={selectedColumn}
          path={question.path}
        />
      )}
      <TableContainer>
        <Table aria-label="collapsible table" style={{ border: '1px solid #f7f7f7' }}>
          <TableHead>
            <TableRow>
              <TableCell />
              {question.matrix?.columns.map((column: IMatrixColumn, key: number) => (
                <TableCell className={column.background == 'selectedTh' ? matrixClasses.selectedTh : ''} key={key}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {column.label}
                    <div>
                      <SettingOutlined
                        className={classes.settingsIcon}
                        onClick={() => {
                          setSelectedColumn(column);
                          setColumnModalOpen(true);
                        }}
                      />
                      <DeleteOutlined className={classes.deleteIcon} onClick={() => deleteColumn(column.id)} />
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {question.matrix?.rows.map((row: IMatrixRow, key: number) => (
              <Row key={key} row={row} cols={question.matrix?.columns || []} path={question.path} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
