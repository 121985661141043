import { PlusOutlined } from '@ant-design/icons';
import { Box, Button } from '@material-ui/core';
import React, { useLayoutEffect } from 'react';
import ConditionSkipLogic from './ConditionSkipLogic';
import ManuallySkipLogic from './ManuallySkipLogic';
import Style from './Style';
import { IQuestion, MainState } from '../../../../types/state';
import { useSelector } from 'react-redux';
import { getQuestionByPath } from '../../../../helpers/utils';

export default function RenderSkipLogic() {
  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);

  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);

  // const mainQuestion = questions.find((question) => question.id === questionItem.id);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);
  const [skipLogicRender, setSkipLogicRender] = React.useState('');
  const classes = Style();

  useLayoutEffect(() => {
    if (currentQuestion.logicList.length > 0) {
      setSkipLogicRender('1');
    }
  }, []);
  switch (skipLogicRender) {
    case '1':
      return <ConditionSkipLogic question={currentQuestion} setSkipLogicRender={setSkipLogicRender} />;
    case '2':
      return <ManuallySkipLogic {...currentQuestion} setSkipLogicRender={setSkipLogicRender} />;
    default:
      return (
        <Box className={classes.skipLogic}>
          <Box className={classes.btnBox}>
            <Button className={classes.btnBlue} variant="outlined" onClick={() => setSkipLogicRender('1')}>
              <PlusOutlined /> <span style={{ paddingLeft: 5 }}>Add condition</span>
            </Button>
            <br />
            <br />
            <Button className={classes.btnOrange} variant="outlined" onClick={() => setSkipLogicRender('2')}>
              ${} Manually enter your skip logic in xls form code
            </Button>
          </Box>
        </Box>
      );
  }
}
