import React from 'react';
import { Checkbox } from '@material-ui/core';
import { labelCol } from '../Style';
import {
  CALCULATE,
  FILE,
  MULTIPLE_SELECT,
  PHOTO,
  QUESTION_MATRIX,
  RATING,
  SELECT_ONE,
} from '../../QuesTypes/Constants';
import { Col, Form, Input, Radio, Select } from 'antd';
import { CUSTOM_LOGIC, NO, YES } from '../constants';
import { useSelector } from 'react-redux';
import {
  updateQuestionApperance,
  updateQuestionDefaultResponse,
  updateQuestionHint,
  updateQuestionHXLTag,
  updateQuestionHXLTagValue,
  updateQuestionLabel,
  updateQuestionListName,
  updateQuestionMandatoryResponse,
  updateQuestionName,
  updateQuestionParameter,
  updateQuestionSeed,
} from '../../../../redux/QuestionSlice';
import {
  IAppearanceElement,
  IDefaultResponse,
  IHintElement,
  IHXLElement,
  ILabelElement,
  IListnameElement,
  IMandatoryResponse,
  INameElement,
  IParameterElement,
  ISeedElement,
} from '../../../../types/InputElement';
import { getLocalizedText, isNameUnique } from '../../../../helpers/utils';
import { IAppearance, IHXLTag, MainState } from '../../../../types/state';
import { TextField } from '@mui/material';

export const NameElement = ({ question, path, dispatch }: INameElement) => {
  const notApplicableQuestions: any = [];
  const found = notApplicableQuestions.find((type: any) => type == question.type);

  const questions = useSelector((state: MainState) => state.question.questions);

  const handleNameBlur = () => {
    const newName = question.name;

    if (!isNameUnique(newName, path, questions)) {
      const formattedPath = path.replace(/,/g, '_');
      const uniqueName = `${newName}_${formattedPath}`;
      dispatch(updateQuestionName({ name: uniqueName, path }));
    }
  };

  return !found ? (
    <Col span={20}>
      <Form.Item
        labelAlign="left"
        labelCol={labelCol}
        label={
          <>
            Data Column Name <span style={{ color: 'red' }}>*</span>
          </>
        }
      >
        <Input
          name="name"
          value={question.name}
          onChange={(e) => {
            dispatch(updateQuestionName({ name: e.target.value, path: path }));
          }}
          onBlur={handleNameBlur}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const KoboScoreChoices = ({ question, onQuestionChange }: any) => {
  const applicableQuestions: any = [RATING];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Kobo Score Choices:" rules={[{ required: true }]}>
        <Input name="koboScoreChoices" value={question.koboScoreChoices} onChange={onQuestionChange} />
      </Form.Item>
    </Col>
  ) : null;
};

export const AcceptedFileElement = ({ question, onQuestionChange }: any) => {
  const applicableQuestions: any = [FILE];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Accepted Files:" rules={[{ required: true }]}>
        <Input name="acceptedFile" value={question.acceptedFile} onChange={onQuestionChange} />
      </Form.Item>
    </Col>
  ) : null;
};

export const LabelElement = ({ question, path, code, isDefault, dispatch }: ILabelElement) => {
  const notApplicableQuestions = [CALCULATE, QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  return !found ? (
    <Col span={20}>
      <Form.Item
        labelAlign="left"
        labelCol={labelCol}
        label={isDefault ? `Label (${code}) - default:` : `Label (${code}):`}
        rules={[{ required: true }]}
      >
        <Input
          // value={question.label[code] ? question.label[code] : ''}
          value={getLocalizedText(question.label, code)}
          onChange={(e) => {
            dispatch(updateQuestionLabel({ label: e.target.value, languageCode: code, path: path }));
          }}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const ErrorMessageElement = ({ question, onNestedQuestionChange, code, isDefault }: any) => {
  return (
    <Col span={20}>
      <Form.Item
        labelAlign="left"
        labelCol={labelCol}
        label={isDefault ? `Error Message (${code}) - default:` : `Error Message (${code}):`}
        rules={[{ required: true }]}
      >
        <Input
          value={question.errorMsg[code] ? question.errorMsg[code] : ''}
          onChange={(e) => {
            onNestedQuestionChange(e, 'errorMsg', code);
          }}
        />
      </Form.Item>
    </Col>
  );
};

export const CalculationElement = ({ question, onQuestionChange }: any) => {
  const applicableQuestions = [CALCULATE];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Calculation:" rules={[{ required: true }]}>
        <Input name="calculation" onChange={onQuestionChange} value={question.calculation} />
      </Form.Item>
    </Col>
  ) : null;
};

export const GuidenceHintElement = ({ question, path, code, isDefault, dispatch }: IHintElement) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  return !found ? (
    <Col span={20}>
      <Form.Item
        labelAlign="left"
        labelCol={labelCol}
        label={isDefault ? `Guidance Hint (${code}) - default:` : `Guidance Hint (${code}):`}
        rules={[{ required: true }]}
      >
        <Input
          name="hint"
          value={getLocalizedText(question.hint, code)}
          onChange={(e) => {
            // dispatch(updateQuestionHint({ id: question.id, label: e.target.value, languageCode: code }));
            dispatch(updateQuestionHint({ path: path, hint: e.target.value, languageCode: code }));
          }}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const MandatoryResponse = ({ question, path, dispatch }: IMandatoryResponse) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  return !found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Mandatory Response:" rules={[{ required: true }]}>
        <Radio.Group
          name="mandatory"
          onChange={(e) => {
            dispatch(updateQuestionMandatoryResponse({ path: path, mandatoryResponse: e.target.value }));
          }}
          value={question.mandatory}
        >
          <Radio value={YES}>Yes</Radio>
          <Radio value={NO}>No</Radio>
          <Radio value={CUSTOM_LOGIC}>Custom Logic</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>
  ) : null;
};

export const DefaultResponse = ({ question, path, dispatch }: IDefaultResponse) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  return !found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Default Response:" rules={[{ required: true }]}>
        <Input
          name="defaultResponse"
          value={question.defaultResponse}
          onChange={(e) => {
            dispatch(updateQuestionDefaultResponse({ path: path, defaultResponse: e.target.value }));
          }}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const Appearance = ({ question, path, dispatch }: IAppearanceElement) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  // const { appearanceOptions } = useSelector((state: MainState) => state.form.masterData);
  const questionTypes = useSelector((state: MainState) => state.form.masterData.questionTypes);
  const types = questionTypes.find((type) => type.name.toLowerCase() === question.type.toLowerCase());
  const appearances = types?.appearances || [];
  return !found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Appearance" rules={[{ required: true }]}>
        <Select
          value={question.appearance}
          allowClear
          onChange={(value) => {
            dispatch(updateQuestionApperance({ path: path, appearance: value }));
          }}
        >
          {appearances &&
            appearances.map((appearance: IAppearance, i: any) => (
              <Select.Option key={i} value={appearance.name}>
                {' '}
                {appearance.title}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </Col>
  ) : null;
};

export const HXLElement = ({ question, path, dispatch }: IHXLElement) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  const { hxlTags } = useSelector((state: any) => state.form.masterData);
  const selectBefore = (
    <Select
      defaultValue="#tags"
      className="select-before"
      value={question.tag || '#tags'}
      onChange={(value) => {
        dispatch(updateQuestionHXLTag({ path: path, tag: value }));
      }}
    >
      {hxlTags &&
        hxlTags.map((hxl: IHXLTag, index: any) => (
          <Select.Option value={hxl.name} key={index}>
            {`#${hxl.title}`}{' '}
          </Select.Option>
        ))}
    </Select>
  );
  return !found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="HXL:" rules={[{ required: true }]}>
        <Input
          value={question.tagValue}
          addonBefore={selectBefore}
          name="tagValue"
          onChange={(e) => {
            dispatch(updateQuestionHXLTagValue({ path: path, value: e.target.value }));
          }}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const Parameters = ({ question, path, dispatch }: IParameterElement) => {
  const applicableQuestions: any = [SELECT_ONE, MULTIPLE_SELECT, PHOTO];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Parameters:" rules={[{ required: true }]}>
        <Checkbox
          name="parameters"
          checked={question.parameters}
          onChange={(e) => {
            dispatch(updateQuestionParameter({ path: path, parameter: e.target.checked }));
          }}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const SeedElement = ({ question, path, dispatch }: ISeedElement) => {
  const applicableQuestions: any = [SELECT_ONE, MULTIPLE_SELECT];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Seed:" rules={[{ required: true }]}>
        <Input
          name="seed"
          value={question.seed}
          onChange={(e) => {
            dispatch(updateQuestionSeed({ path: path, seed: e.target.value }));
          }}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const ListName = ({ question, path, dispatch }: IListnameElement) => {
  const applicableQuestions = [SELECT_ONE, MULTIPLE_SELECT];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={{ span: 2, offset: 2 }} label="List Name:" rules={[{ required: true }]}>
        <Input
          name="list_name"
          value={question.list_name}
          onChange={(e) => {
            dispatch(updateQuestionListName({ path: path, listName: e.target.value }));
          }}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const ListNameMui = ({ question, path, dispatch }: IListnameElement) => {
  const applicableQuestions = [SELECT_ONE, MULTIPLE_SELECT];
  const found = applicableQuestions.find((type: any) => type === question.type);

  return found ? (
    <TextField
      fullWidth
      id="list-name"
      label="List name"
      size="small"
      name="list_name"
      value={question.list_name}
      onChange={(e) => {
        dispatch(updateQuestionListName({ path: path, listName: e.target.value }));
      }}
      error={!question.list_name} // Optional: Display error if list_name is empty
      helperText={!question.list_name ? 'List Name is required.' : ''}
    />
  ) : null;
};

export const MatrixAppearance = ({ question }: any) => {
  const applicableQuestions: any = [QUESTION_MATRIX];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span="20">
      <Form.Item labelAlign="left" labelCol={labelCol} label="Appearance:">
        <Checkbox
          name="appearance"
          checked={question.appearance}
          // onChange={() => onChange({ ...question, appearance: !question.appearance })}
        />
        Show all questions in this group on the same screen
      </Form.Item>
    </Col>
  ) : null;
};

export const Repeat = ({ question }: any) => {
  const applicableQuestions: any = [QUESTION_MATRIX];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span="20">
      <Form.Item labelAlign="left" labelCol={labelCol} label="Repeat:">
        <Checkbox
          name="repeat"
          checked={question.repeat}
          // onChange={() => onChange({ ...question, repeat: !question.repeat })}
        />
        Repeat this group if necessary
      </Form.Item>
    </Col>
  ) : null;
};
