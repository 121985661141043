import _ from 'lodash';
import { IForm, IHint, ILabel, IOptionName, IQuestion } from '../types/state';
import { getNodeIndex, travelToNode } from '../redux/QuestionService';

import DatetimeSVG from '../Icons/date-time.svg';
import TextSvg from '../Icons/text.svg';
import SelectOneSvg from '../Icons/select-one.svg';
import MultiSelectSvg from '../Icons/select-many.svg';
import NumberSvg from '../Icons/number.svg';
import NoteSvg from '../Icons/note.svg';
import CalculateSvg from '../Icons/calculate.svg';
import DateSvg from '../Icons/date.svg';
import PhotoSvg from '../Icons/date.svg';
import DecimalSvg from '../Icons/decimal.svg';
import FileSvg from '../Icons/file.svg';
import AudioSvg from '../Icons/audio.svg';
import VideoSvg from '../Icons/video.svg';
import RatingSvg from '../Icons/rating.svg';
import LocationSvg from '../Icons/location.svg';
import BarcodeSvg from '../Icons/barcode.svg';
import AcknowledgeSvg from '../Icons/acknowledge.svg';
import AreaSvg from '../Icons/area.svg';
import RankingSvg from '../Icons/ranking.svg';
import MatrixSvg from '../Icons/question-matrix.svg';
import RangeSvg from '../Icons/range.svg';
import LineSVG from '../Icons/line.svg';
import TimeSVG from '../Icons/time.svg';

/** Interface for an object that is allowed to have any property */
export interface FlexObject {
  [key: string]: any;
}

export const deepClone = (ob: any) => {
  if (Object.keys(ob).length > 0) {
    return JSON.parse(JSON.stringify(ob));
  } else return {};
};

export const checkFormValidation = (form: IForm) => {
  if (_.isEmpty(form.formTitle)) return { valid: false, msg: 'Form title is required' };
  if (_.isEmpty(form.formName)) return { valid: false, msg: 'Form name is required' };
  if (_.isEmpty(form.formStyle)) return { valid: false, msg: 'Form style is required' };
  return { valid: true };
};

// export const getLabel = (label: ILabel, languageCode: string): string => {
//   return typeof label === 'string' ? label : label[languageCode] || '';
// };

// export const getLocalizedText = (hint: IHint, languageCode: string): string => {
//   return typeof hint === 'string' ? hint : hint[languageCode] || '';
// };

// export const getLocalizedText = (name: IOptionName, languageCode: string): string => {
//   return typeof name === 'string' ? name : name[languageCode] || '';
// };

// export const geterrorMessage = (name: IOptionName, languageCode: string): string => {
//   return typeof name === 'string' ? name : name[languageCode] || '';
// };

type LocalizedText = string | { [key: string]: string };

export const getLocalizedText = (text: LocalizedText, languageCode: string): string => {
  return typeof text === 'string' ? text : text[languageCode] || '';
};

export const getLableByObject = (label: ILabel, languageCode: string) => {
  let existingLabel: Record<string, string> = {};
  if (typeof label === 'string') {
    existingLabel = { [languageCode]: label };
  } else if (typeof label === 'object' && label !== null) {
    existingLabel = { ...label };
  }
  return existingLabel;
};

export const getLocalizedTextByObject = (hint: IHint, languageCode: string) => {
  let existingHint: Record<string, string> = {};
  if (typeof hint === 'string') {
    existingHint = { [languageCode]: hint };
  } else if (typeof hint === 'object' && hint !== null) {
    existingHint = { ...hint };
  }
  return existingHint;
};

export const getQuestionByPath = (questions: IQuestion[], path: string): IQuestion => {
  // Use travelToNode to get the nested list of questions
  const questionList = travelToNode(questions, path);

  // Use getNodeIndex to get the index of the question
  const questionIndex = getNodeIndex(path);

  // Return the specific question using the index
  return questionList[questionIndex];
};

export function removeLastPathSegment(path: string): string {
  // Split the path into segments
  const segments = path.split(',');

  // Check if there are segments to remove
  if (segments.length === 0) {
    return ''; // Return empty if there are no segments
  }

  // Remove the last segment
  segments.pop();

  // Join the remaining segments back into a string and return
  return segments.join(',');
}

export const updateQuestionPathsRearrange = (questions: IQuestion[], parentPath: number[] = []): IQuestion[] => {
  return questions.map((question, index) => {
    const currentPath = [...parentPath, index]; // Build the path as an array of numbers
    const pathString = currentPath.join(','); // Convert number[] to string

    question.path = pathString; // Assign the string path

    // If the question is a group, update the paths of its nested questions
    if (question.type === 'Group Question' && question.questions && question.questions.length > 0) {
      question.questions = updateQuestionPathsRearrange(question.questions, currentPath); // Recursively update the nested questions' paths
    }

    return question; // Always return the updated question
  });
};

export const hasConflictingSelection = (newPath: string, selectedPaths: string[]): boolean => {
  const newPathSegments = newPath.split(',').length;

  // Check if the selected paths have the same number of segments as the new path
  return selectedPaths.some((selectedPath: string) => {
    const selectedSegments = selectedPath.split(',').length;
    return selectedSegments !== newPathSegments;
  });
};

const iconMap: Record<string, any> = {
  DatetimeSVG,
  TextSvg,
  SelectOneSvg,
  MultiSelectSvg,
  NumberSvg,
  NoteSvg,
  CalculateSvg,
  DateSvg,
  DecimalSvg,
  FileSvg,
  PhotoSvg,
  AudioSvg,
  VideoSvg,
  RatingSvg,
  LocationSvg,
  BarcodeSvg,
  AcknowledgeSvg,
  AreaSvg,
  RankingSvg,
  MatrixSvg,
  RangeSvg,
  LineSVG,
  TimeSVG,
};

export const getIconByName = (iconName: string) => {
  return iconMap[iconName] || null; // Fallback to null if the icon is not found
};

export function getAllQuestionsAtPath(path: string, questions: IQuestion[], stopFlag = { stop: false }): IQuestion[] {
  const allQuestions: IQuestion[] = [];

  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];

    // If the stop flag is true, break out of the loop
    if (stopFlag.stop) {
      break;
    }

    // If the question's path matches the provided path, set the stop flag and break
    if (question.path === path) {
      stopFlag.stop = true;
      break;
    }

    // Only push the question if it is not a 'Group Question' and its type is either 'Text' or 'select_one'
    if (question.type !== 'Group Question' && (question.type === 'Text' || question.type === 'select_one')) {
      allQuestions.push(question);
    }

    // If there are nested questions, handle recursion
    if (question.questions && question.questions.length > 0) {
      const nestedQuestions = getAllQuestionsAtPath(path, question.questions, stopFlag);
      allQuestions.push(...nestedQuestions);
    }
  }

  return allQuestions;
}

// export function isNameUnique(name: string, path: string, questions: IQuestion[]): boolean {
//   // Base case: if there are no questions, the name is unique
//   if (questions.length === 0) {
//     return true;
//   }

//   // Use a for loop to check each question
//   for (let i = 0; i < questions.length; i++) {
//     const question = questions[i];

//     // If the question's name matches the given name and the path is different, it's not unique
//     if (question.name === name && question.path !== path) {
//       return false; // Name is not unique
//     }

//     // Check nested questions if they exist
//     if (question.questions.length > 0) {
//       const isUniqueInNested = isNameUnique(name, path, question.questions);
//       if (!isUniqueInNested) {
//         return false; // Name is not unique in nested questions
//       }
//     }
//   }

//   return true;
// }

export function isNameUnique(name: string, path: string | undefined, questions: IQuestion[]): boolean {
  // Base case: if there are no questions, the name is unique
  if (questions.length === 0) {
    return true;
  }

  // Use a for loop to check each question
  for (const question of questions) {
    // If the question's name matches the given name and the path is provided and different, it's not unique
    if (question.name === name) {
      if (path && question.path === path) {
        continue;
      }
      return false; // Name is not unique
    }

    // Check nested questions if they exist
    if (question.questions.length > 0) {
      const isUniqueInNested = isNameUnique(name, path, question.questions);
      if (!isUniqueInNested) {
        return false;
      }
    }
  }

  return true;
}

export function generateUniqueId(length: number) {
  return Math.random()
    .toString(36)
    .substring(2, 2 + length);
}

export function formatFormName(input: string, maxLength = 32): string {
  // Replace spaces with underscores
  let formattedInput = input.replace(/\s+/g, '_');

  // Limit the input to the maximum length (default is 32)
  if (formattedInput.length > maxLength) {
    formattedInput = formattedInput.substring(0, maxLength);
  }

  return formattedInput;
}

export const getOrdinalSuffix = (number: number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const value = number % 100;
  return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
};

export function findQuestionByKey(
  questions: IQuestion[],
  key: keyof IQuestion,
  value: string,
  excludePath?: string,
): IQuestion | null {
  // Base case: if there are no questions, return null
  if (questions.length === 0) {
    return null;
  }

  for (const question of questions) {
    // Check if the question has the specified key-value pair
    if (question[key] === value) {
      // If an excludePath is provided, skip the question if its path matches the excludePath
      if (excludePath && question.path === excludePath) {
        continue;
      }
      return question; // Found the question
    }

    // Check nested questions recursively
    if (question.questions.length > 0) {
      const foundQuestion = findQuestionByKey(question.questions, key, value, excludePath);
      if (foundQuestion) {
        return foundQuestion;
      }
    }
  }

  return null; // No matching question found
}

export function isQuestionDependent(questionId: string, questions: IQuestion[]): boolean {
  // Base case: if there are no questions, return false
  if (questions.length === 0) {
    return false;
  }

  // Iterate over each question
  for (const question of questions) {
    // Check if this question's dependencyQuestion exists and matches the questionId
    if (question.dependencyQuestion?.id === questionId) {
      return true; // Found the dependent question
    }

    // Check nested questions recursively
    if (question.questions.length > 0) {
      const isDependent = isQuestionDependent(questionId, question.questions);
      if (isDependent) {
        return true;
      }
    }
  }

  return false; // No dependency found for the questionId
}

export function findDependentQuestion(questionId: string, questions: IQuestion[]): string | null {
  // Base case: if there are no questions, return null
  if (questions.length === 0) {
    return null;
  }

  for (const question of questions) {
    // Check if this question's dependencyQuestion exists and matches the questionId
    if (question.dependencyQuestion?.id === questionId) {
      return question.name; // Or return question.label.EN if you want the label
    }

    // Check nested questions recursively
    if (question.questions.length > 0) {
      const dependentName = findDependentQuestion(questionId, question.questions);
      if (dependentName) {
        return dependentName;
      }
    }
  }

  return null; // No dependency found for the questionId
}

// Utility function to traverse questions and apply a callback to each question
export function traverseQuestions(questions: IQuestion[], callback: (question: IQuestion) => boolean): boolean {
  // Base case: if there are no questions, return false
  if (questions.length === 0) {
    return false;
  }

  for (const question of questions) {
    // Call the callback function with the current question
    if (callback(question)) {
      return true; // Stop traversal if callback returns true
    }

    // Recursively traverse nested questions
    if (question.questions.length > 0) {
      if (traverseQuestions(question.questions, callback)) {
        return true;
      }
    }
  }

  return false; // No match found
}

export function validateQuestionLabels(questions: IQuestion[]): { path: string; msg: string }[] {
  const errors: { path: string; msg: string }[] = [];

  function traverseQuestions(questions: IQuestion[], path: string) {
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const currentPath = path ? `${path}.${i}` : `${i}`;

      // Check if the label is empty based on the ILabel type
      if (typeof question.label === 'string') {
        if (question.label.trim() === '') {
          errors.push({
            path: currentPath,
            msg: `Label is empty at question path ${currentPath}`,
          });
        }
      } else if (typeof question.label === 'object') {
        // For each language, check if the label value is an empty string
        for (const [lang, labelText] of Object.entries(question.label)) {
          if (labelText.trim() === '') {
            errors.push({
              path: currentPath,
              msg: `Label is empty for language "${lang}" at question path ${currentPath}`,
            });
          }
        }
      }

      // Recursively validate nested questions if present
      if (question.questions && question.questions.length > 0) {
        traverseQuestions(question.questions, currentPath);
      }
    }
  }

  traverseQuestions(questions, '');
  return errors;
}
