import React from 'react';
import { Col, Form, Input, Checkbox, Row } from 'antd';
import Style, { labelCol } from '../Style';
import { useDispatch, useSelector } from 'react-redux';
import { toggleGroupApperance, toggleRepeat, updateGroupLabel, updateGroupName } from '../../../../redux/QuestionSlice';
import { IQuestion, MainState } from '../../../../types/state';
import { getQuestionByPath } from '../../../../helpers/utils';

interface GroupOptionsProps {
  question: IQuestion; // The question data the group option relates to
}

export default function GroupOptions({ question }: GroupOptionsProps) {
  const classes = Style();
  const dispatch = useDispatch();
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);

  const questions = useSelector((state: MainState) => state.question.questions);
  const selectedQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  // const currentQuestion = questions.find((question) => question.id === selectedQuestion.id) || selectedQuestion;
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path) || selectedQuestion;

  // const onQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   dispatch(updateGroupLabel({ id: currentQuestion.id, languageCode: defaultLanguage.code, label: e.target.value }));
  // };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateGroupName({ path: currentQuestion.path, languageCode: defaultLanguage.code, name: e.target.value }));
  };

  const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateGroupLabel({ path: selectedQuestion.path, languageCode: defaultLanguage.code, label: e.target.value }),
    );
  };

  const onGroupApperanceChange = () => {
    dispatch(toggleGroupApperance({ path: currentQuestion.path }));
  };

  const onRepeatChange = () => {
    dispatch(toggleRepeat({ path: currentQuestion.path }));
  };

  return (
    <div className={classes.quesOptionSection}>
      <Row justify="start">
        <Col span="20">
          <Form.Item labelAlign="left" labelCol={labelCol} label="Data Column Name:">
            <Input name="name" value={currentQuestion.name} className={classes.inputSize} onChange={onNameChange} />
          </Form.Item>
        </Col>
        <Col span="20">
          <Form.Item labelAlign="left" labelCol={labelCol} label="Label:">
            <Input
              name="label"
              value={
                typeof currentQuestion.label === 'string'
                  ? currentQuestion.label
                  : currentQuestion.label[defaultLanguage.code] || ''
              }
              onChange={onLabelChange}
            />
          </Form.Item>
        </Col>

        <Col span="20">
          <Form.Item labelAlign="left" labelCol={labelCol} label="Group Appearance:">
            <Checkbox name="parameters" checked={currentQuestion.groupAppearance} onChange={onGroupApperanceChange} />
          </Form.Item>
        </Col>

        <Col span="20">
          <Form.Item labelAlign="left" labelCol={labelCol} label="Repeat:">
            <Checkbox name="repeat" checked={currentQuestion.repeat} onChange={onRepeatChange} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
