import _ from 'lodash';
import { ILanguageData } from '../types/state';
import { IAction } from '../types/common';

/**
 *  Language Reducers
 */

const addLanguage = (state: ILanguageData, action: IAction) => {
  state.languageList = action.payload;
};

const addSelectedLanguage = (state: ILanguageData, action: IAction) => {
  state.selectedLanguageList = action.payload;
};

const addDefaultLanguage = (state: ILanguageData, action: IAction) => {
  state.defaultLanguage = action.payload;
};

export default { addLanguage, addSelectedLanguage, addDefaultLanguage };
