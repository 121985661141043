import { IQuestion } from '../../../types/state';
import { v4 as uuidv4 } from 'uuid';
export interface Question {
  name: string;
  hint: any;
  type: string;
  mandatory: string;
  defaultResponse: string;
  appearance: string;
  index?: number;
  seed?: string;
  tag: string;
  tagDescription: string;
  parameters: boolean;
  choice?: any;
  range?: any;
  logicList: any[];
  ratings?: {
    columns: {
      id: string | number;
      title?: string;
      subTitle?: string;
    }[];
    rows: {
      id: string;
      title: string;
      subTitle: string;
    }[];
  };
  matrix?: {
    columns: {
      id: string | number;
      label?: string;
      dataColumnPrefix?: string;
      responseType: string;
      isChoice?: boolean;
      required?: boolean;
      expand: string;
      background?: string;
      choices?: {
        id: string | number;
        colId: string | number;
        label?: string;
        dataColumnName: string;
      }[];
    }[];
    rows: {
      id: string;
      label?: string;
      dataColumnPrefix?: string;
    }[];
  };
  validationCode: string;
  errorMsg: any;
  dataColumnName: string;
  repeat: boolean;
  questions?: any[];
  path: string;
  expanded?: boolean;
  groupAppearance: boolean;
  label: any;
  requiredMessage: any;
  list_name: string;
  calculation: string;
  validation: any[];
  acceptedFile: string;
  koboScoreChoices: string;
  manualSkipLogic: string;
  manualValidation: string;
  ranks: any;
}

export const questionDefaultValue: IQuestion = {
  name: 'new_question',
  label: '',
  hint: '',
  type: '',
  mandatory: '',
  defaultResponse: '',
  appearance: '',
  index: -1,
  seed: '',
  tag: '',
  tagValue: '',
  parameters: false,
  acceptedFile: '',
  koboScoreChoices: '',
  manualSkipLogic: '',
  manualValidation: '',
  // ratings: {
  //   columns: [
  //     {
  //       title: '',
  //       subTitle: '',
  //       id: '1',
  //     },
  //     {
  //       title: 'Option',
  //       subTitle: 'automatic',
  //       id: '2',
  //     },
  //     {
  //       title: 'Option',
  //       subTitle: 'automatic',
  //       id: '3',
  //     },
  //   ],
  //   rows: [
  //     {
  //       id: '1',
  //       title: 'Option',
  //       subTitle: 'automatic',
  //     },
  //   ],
  // },

  // matrix: {
  //   columns: [],
  //   rows: [],
  // },
  logicList: [],
  validationCode: '',
  errorMsg: {},
  dataColumnName: '',
  repeat: false,
  questions: [],
  path: '',
  expanded: false,
  groupAppearance: false,
  list_name: '',
  calculation: '',
  requiredMessage: {
    english: '',
    bangla: '',
  },
  validation: [],
  // ranks: [],
  id: uuidv4(),
  chosen: false,
  selected: false,
};

export const createQuestionDefaultValue = (languageCode?: string): IQuestion => {
  const defaultLabel = languageCode ? { [languageCode]: '' } : '';

  return {
    name: 'new_question',
    label: defaultLabel,
    hint: '',
    type: '',
    mandatory: '',
    defaultResponse: '',
    appearance: '',
    index: -1,
    seed: '',
    tag: '',
    tagValue: '',
    parameters: false,
    acceptedFile: '',
    koboScoreChoices: '',
    manualSkipLogic: '',
    manualValidation: '',
    logicList: [],
    validationCode: '',
    errorMsg: {},
    dataColumnName: '',
    repeat: false,
    questions: [],
    path: '',
    expanded: false,
    groupAppearance: false,
    list_name: '',
    calculation: '',
    requiredMessage: {
      english: '',
      bangla: '',
    },
    validation: [],
    // ranks: [],
    id: uuidv4(),
    chosen: false,
    selected: false,
  };
};

export const activeStyle = (iconHover: any) => ({
  border: '1px solid #2196f3',
  width: '100%',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  backgroundColor: iconHover.bg ? iconHover.bg : 'rgb(251, 394, 521)',
});

export const inActiveStyle = (iconHover: any) => ({
  border: '1px solid lightgray',
  width: '100%',
  height: '100%',
  backgroundColor: iconHover.bg,
});
