import { CloseOutlined } from '@ant-design/icons';
import { Box, IconButton } from '@material-ui/core';
import { Form, Input, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import ContactStyle from './Style';
import { updateErrorMessage, updateManualValidation } from '../../../../redux/QuestionSlice';
import { getLocalizedText, getQuestionByPath } from '../../../../helpers/utils';
import { IQuestion, MainState } from '../../../../types/state';

export default function ManuallyValidation({ question, setValidationRender, hideCloseBtn, onEvent }: any) {
  const classes = ContactStyle();
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);

  // const mainQuestion = questions.find((question) => question.id === questionItem.id);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedLanguages = useSelector((state: MainState) => state.language.selectedLanguageList);
  const dispatch = useDispatch();
  return (
    <>
      <Box className={classes.closeOutlinedIcon}>
        {hideCloseBtn ? null : (
          <IconButton
            onClick={() => {
              setValidationRender('');
              // onEvent('manualValidation', '');
              dispatch(updateManualValidation({ path: selectedQuestion.path, manualValidation: '' }));
            }}
          >
            <CloseOutlined className={classes.icon} />
          </IconButton>
        )}
      </Box>
      <Box className={classes.manualValidation}>
        <Box>
          <Typography.Text className={classes.manualValidationText}>Enter your validation code here :</Typography.Text>
          <Input.TextArea
            value={currentQuestion.manualValidation}
            autoSize={{ minRows: 6, maxRows: 10 }}
            // onChange={(e: any) => onEvent('manualValidation', e.target.value)}
            onChange={(e) =>
              dispatch(updateManualValidation({ path: selectedQuestion.path, manualValidation: e.target.value }))
            }
          />
        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <Typography.Text className={classes.manualValidationText}>
            {' '}
            {`Error Message ( ${defaultLanguage.code} ) - default:`}
          </Typography.Text>
          <Input.TextArea
            value={getLocalizedText(currentQuestion.errorMsg, defaultLanguage.code)}
            autoSize={{ minRows: 2, maxRows: 4 }}
            // onChange={(e: any) => onEvent('errorMsg', e.target.value)}
            onChange={(e) =>
              dispatch(
                updateErrorMessage({
                  path: selectedQuestion.path,
                  languageCode: defaultLanguage.code,
                  label: e.target.value,
                }),
              )
            }
          />
        </Box>
        {selectedLanguages &&
          selectedLanguages.map((language) => (
            <Box sx={{ marginTop: '30px' }} key={language.id}>
              <Typography.Text className={classes.manualValidationText}>
                {' '}
                {`Error Message ( ${language.code} ):`}
              </Typography.Text>
              <Input.TextArea
                value={getLocalizedText(currentQuestion.errorMsg, language.code)}
                autoSize={{ minRows: 2, maxRows: 4 }}
                // onChange={(e: any) => onEvent('errorMsg', e.target.value)}
                onChange={(e) =>
                  dispatch(
                    updateErrorMessage({
                      path: selectedQuestion.path,
                      languageCode: language.code,
                      label: e.target.value,
                    }),
                  )
                }
              />
            </Box>
          ))}
      </Box>
    </>
  );
}
