import React from 'react';
import { Grid, Paper, Typography, Collapse } from '@mui/material';
import { ErrorMessageElement } from '../QuesOptFields';
import { useSelector } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const ChoiceGroup = (props: any) => {
  const defaultLanguage = useSelector((state: any) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: any) => state.language.selectedLanguageList);

  return (
    <Grid container sx={{ pb: 3 }}>
      <Paper sx={{ borderTop: 1, borderColor: 'grey.300', width: '100%', mr: '20px', pb: 1 }} elevation={2}>
        {' '}
        <Grid item style={{ color: 'grey' }}>
          <Grid
            container
            alignItems="center"
            direction="row"
            aria-label="settings"
            onClick={() => props.setCollapseChoice(!props.collapseChoice)}
          >
            <Typography style={{ color: 'grey', fontSize: 15 }} sx={{ pl: 2, pr: '3px' }}>
              Choice
            </Typography>
            <CaretDownOutlined />
          </Grid>
          <Collapse in={!props.collapseChoice}>
            <Grid container sx={{ position: 'relative' }}>
              <Grid item xs={12}>
                {props.question.choice.isDefault && (
                  <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                    {props.question.choice.options &&
                      props.question.choice.options.map((data: any, index: any) => (
                        <Paper
                          sx={{ margin: '20px', width: '90%', p: 3, borderTop: 1, borderColor: 'grey.400' }}
                          key={index}
                          elevation={1}
                        >
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{ py: '10px', px: '20px' }}
                            key={index}
                          >
                            <Grid container alignItems="center">
                              <Grid item xs={2}>
                                <Typography variant="subtitle2">
                                  {`Option ( ${defaultLanguage.code} - default )`}{' '}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Input
                                  name="option"
                                  value={data.option[defaultLanguage.code]}
                                  onChange={(e: any) => props.optionValueChange(index, e, defaultLanguage.code)}
                                />
                              </Grid>
                              <Grid item xs={2} sx={{ marginLeft: '40px' }}>
                                <Typography variant="subtitle2">Value </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Input
                                  name="value"
                                  value={data.value}
                                  onChange={(e: any) => props.optionValueChange(index, e, defaultLanguage.code)}
                                />
                              </Grid>
                            </Grid>

                            {selectedLanguage.map((lang: any, i: number) => (
                              <Grid container alignItems="center" key={i} sx={{ my: '20px' }}>
                                <Grid item xs={2}>
                                  <Typography variant="subtitle2">{`Option ( ${lang.code} ) `} </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Input
                                    name="option"
                                    value={data.option[lang.code]}
                                    onChange={(e: any) => props.optionValueChange(index, e, lang.code)}
                                  />
                                </Grid>
                                <Grid item xs={2} sx={{ marginLeft: '40px' }}>
                                  <Typography variant="subtitle2">Value </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Input
                                    name="value"
                                    value={data.value}
                                    onChange={(e: any) => props.optionValueChange(index, e, lang.code)}
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Paper>
                      ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ChoiceGroup;
