import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Box, IconButton } from '@material-ui/core';
import { Button, Form, Input, Select, Typography } from 'antd';
import { Grid, TextField, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useLayoutEffect } from 'react';
import ContactStyle from './Style';
import { getLocalizedText, getQuestionByPath } from '../../../../helpers/utils';
import { updateErrorMessage } from '../../../../redux/QuestionSlice';
import { IQuestion, MainState } from '../../../../types/state';

export default function ConditionValidation(props: any) {
  const classes = ContactStyle();
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: MainState) => state.language.selectedLanguageList);
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);

  // const mainQuestion = questions.find((question) => question.id === questionItem.id);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);
  const selectedLanguages = useSelector((state: MainState) => state.language.selectedLanguageList);
  const dispatch = useDispatch();

  const {
    question: { validation },
  } = props;

  // const onQuestionObjectChange = (e: any, parent: any, code: any) => {
  //   console.log('parent', e.target.name, parent, code, e.target.value, props.question);
  //   props.onChange({
  //     ...props.question,
  //     [e.target.name]: { ...props.question[e.target.name], [code]: e.target.value },
  //   });
  // };

  const resetValidation: any[] = [];

  return (
    <>
      <Box className={classes.closeOutlinedIcon}>
        <IconButton
          onClick={() => {
            props.setValidationRender('');
            props.onEvent('validation', resetValidation);
          }}
        >
          <CloseOutlined className={classes.icon} />
        </IconButton>
      </Box>

      <Box className={classes.conditionValidationText}>
        <p>This question will be valid only displayed if the following conditions apply </p>
      </Box>
      {validation.map((data: any, index: number) => (
        <Box key={index} margin={3}>
          <Box className={classes.conditionValidationForm}>
            <IconButton className={classes.minusOutlinedIcon} onClick={(e: any) => props.removeValidation(index)}>
              <MinusOutlined className={classes.icon} />
            </IconButton>
            <Box margin={5}>
              <Box className={classes.conditionValidationFields}>
                <Form.Item
                  style={{ width: '100%' }}
                  label="The question’s response has to be:"
                  rules={[{ required: true }]}
                >
                  <Select
                    value={data.condition}
                    onChange={(e: any) => props.onChange(data, index, e, 'condition')}
                    allowClear
                  >
                    <Select.Option value="equal"> = </Select.Option>
                    <Select.Option value="not_equal"> != </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item style={{ width: '100%' }} label="Value" rules={[{ required: true }]}>
                  <Input
                    value={data.value}
                    onChange={(e: any) => props.onChange(data, index, e.target.value, 'value')}
                  />
                </Form.Item>
              </Box>
              {/* <Box className={classes.conditionValidationFields}>
                <Form.Item style={{ width: '100%' }} label="Error Message:" rules={[{ required: true }]}>
                  <Input
                    value={data.errorMsg}
                    onChange={(e: any) => props.onChange(data, index, e.target.value, 'errorMsg')}
                  />
                </Form.Item>
              </Box> */}
            </Box>
          </Box>
        </Box>
      ))}
      <Grid container>
        <Grid container item justifyContent="right">
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            size="large"
            onClick={(e: any) => {
              props.addValidation();
            }}
          >
            Add Validation
          </Button>
        </Grid>

        {props.question.validationCriteria && (
          <Grid container item justifyContent="center" xs={12}>
            <TextField
              style={{ width: '100%' }}
              sx={{ mx: 20 }}
              select
              id="standard-basic"
              label="Select a criteria"
              variant="standard"
              value={props.question.validationCriteria}
              onChange={(e: any) => {
                props.onEvent('validationCriteria', e.target.value);
              }}
            >
              <MenuItem value="all">Question should match all of these validation criteria</MenuItem>
              <MenuItem value="any">Question should match any of these validation criteria</MenuItem>
            </TextField>
          </Grid>
        )}
        <Box className={classes.conditionValidationFields} style={{ marginTop: '30px' }}>
          <Form.Item
            style={{ width: '100%' }}
            label={`Error Message (${defaultLanguage.code}) - default:`}
            rules={[{ required: true }]}
          >
            <Input
              value={getLocalizedText(currentQuestion.errorMsg, defaultLanguage.code)}
              onChange={(e) =>
                dispatch(
                  updateErrorMessage({
                    path: selectedQuestion.path,
                    languageCode: defaultLanguage.code,
                    label: e.target.value,
                  }),
                )
              }
            />
          </Form.Item>
        </Box>

        {selectedLanguages &&
          selectedLanguages.map((language) => (
            <Box className={classes.conditionValidationFields} style={{ marginTop: '30px' }} key={language.code}>
              <Form.Item
                style={{ width: '100%' }}
                label={`Error Message (${language.code}):`}
                rules={[{ required: true }]}
              >
                <Input
                  value={getLocalizedText(currentQuestion.errorMsg, language.code)}
                  onChange={(e) =>
                    dispatch(
                      updateErrorMessage({
                        path: selectedQuestion.path,
                        languageCode: language.code,
                        label: e.target.value,
                      }),
                    )
                  }
                />
              </Form.Item>
            </Box>
          ))}
      </Grid>
    </>
  );
}
