import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Box } from '@material-ui/core';
import { Table } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { HeaderTitle, RadioField } from '../../constants';
import ContactStyle from '../../Style';
import { AlignType } from 'rc-table/lib/interface';
import { v4 as uuid4 } from 'uuid';
import { Button, Grid, IconButton, Paper, TableContainer, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addRatingColumn,
  addRatingRow,
  removeRatingColumn,
  removeRatingRow,
  updateRatingColumnSubtitle,
  updateRatingColumnTitle,
  updateRatingRowSubtitle,
  updateRatingRowTitle,
} from '../../../../redux/QuestionSlice';
import { getQuestionByPath } from '../../../../helpers/utils';
import { IQuestion, MainState, ILanguage, IRatingColumn, IRatingRow } from '../../../../types/state';

export default function Rating() {
  const dispatch = useDispatch();
  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);

  const classes = ContactStyle();

  // Column header change functions
  const onHeaderTitleChange = (dataIndex: string, value: string) => {
    dispatch(
      updateRatingColumnTitle({
        path: currentQuestion.path,
        columnId: dataIndex,
        newTitle: value,
      }),
    );
  };

  const onHeaderSubTitleChange = (dataIndex: string, value: string) => {
    dispatch(
      updateRatingColumnSubtitle({
        path: currentQuestion.path,
        columnId: dataIndex,
        newSubTitle: value,
      }),
    );
  };

  const onClickAddColumn = () => {
    const newColumn: IRatingColumn = {
      title: 'Option',
      subTitle: 'automatic',
      id: uuid4(),
    };
    dispatch(addRatingColumn({ path: currentQuestion.path, column: newColumn }));
  };

  const onClickDeleteColumn = (column: IRatingColumn) => {
    dispatch(removeRatingColumn({ path: currentQuestion.path, columnId: column.id }));
  };

  // Row title change functions
  const onRowTitleChange = (dataIndex: string, value: string) => {
    dispatch(
      updateRatingRowTitle({
        path: currentQuestion.path,
        rowId: dataIndex,
        newTitle: value,
      }),
    );
  };

  const onRowSubTitleChange = (dataIndex: string, value: string) => {
    dispatch(
      updateRatingRowSubtitle({
        path: currentQuestion.path,
        rowId: dataIndex,
        newSubTitle: value,
      }),
    );
  };

  const onClickAddRow = () => {
    const newRow = {
      id: uuid4(),
      title: 'Enter your question',
      subTitle: 'automatic',
    };
    dispatch(addRatingRow({ path: currentQuestion.path, row: newRow }));
  };

  const onClickDeleteRow = (row: IRatingRow) => {
    dispatch(removeRatingRow({ path: currentQuestion.path, rowId: row.id }));
  };

  const TitleField = (record: IRatingRow) => (
    <>
      <div>
        <HeaderTitle dataIndex={record.id} title={record.title} onHeaderChange={onRowTitleChange} />
        <HeaderTitle dataIndex={record.id} title={record.subTitle} onHeaderChange={onRowSubTitleChange} />
      </div>

      <Box className={classes.deleteIconPosition} id="popover-anchor1" onClick={() => onClickDeleteRow(record)}>
        <DeleteOutlined className={classes.deleteIcon} />
      </Box>
    </>
  );

  const formattingColumns = (columns: IRatingColumn[] = []) => {
    return columns.map((column, index) => ({
      key: column.id,
      dataIndex: column.id,
      align: 'center' as AlignType,
      width: 100,
      title: (
        <div>
          <HeaderTitle dataIndex={column.id} title={column.title} onHeaderChange={onHeaderTitleChange} />
          <HeaderTitle dataIndex={column.id} title={column.subTitle} onHeaderChange={onHeaderSubTitleChange} />
          {index !== 0 && (
            <Box className={classes.deleteIconPosition} onClick={() => onClickDeleteColumn(column)}>
              <DeleteOutlined className={classes.deleteIcon} />
            </Box>
          )}
        </div>
      ),
      render: index === 0 ? (text: any, record: IRatingRow) => TitleField(record) : RadioField,
    }));
  };

  const formattingDataSource = (dataSource: IRatingRow[] = []) => {
    return dataSource.map((item) => ({ ...item, key: item.id }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
          <Button variant="contained" startIcon={<PlusOutlined />} onClick={onClickAddColumn}>
            Add Column
          </Button>
        </Grid>

        {/* Table Section */}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              pagination={false}
              dataSource={formattingDataSource(currentQuestion.ratings?.rows)}
              columns={formattingColumns(currentQuestion.ratings?.columns)}
              className={classes.table}
              scroll={{ x: '100%' }}
            />
          </TableContainer>
        </Grid>

        {/* Add Row Button Below Table */}
        <Grid item>
          <Button variant="contained" startIcon={<PlusOutlined />} onClick={onClickAddRow}>
            Add Row
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
