import React, { ReactNode, SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IQuestion, MainState } from '../../../types/state';
import { getLocalizedText, getQuestionByPath } from '../../../helpers/utils';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Collapse } from 'antd';
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import GroupConfig from '../Settings/groupSettings';
import { deleteGroup, selectQuestion, toggleSettingsTab, updateGroupLabel } from '../../../redux/QuestionSlice';
import QuestionAddTool from '../../../components/Poper';
import styled from 'styled-components';
import Style from './Style';
import { useTheme } from '@material-ui/core/styles';

const { Panel } = Collapse;

const StyledBlockWrapper = styled.div`
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
  cursor: move;
`;

type GroupComponentPropsType = {
  groupItem: IQuestion & { path: string };
  children: ReactNode;
};

export default function GroupComponent({ groupItem, children }: GroupComponentPropsType) {
  const theme = useTheme();
  const classes = Style();
  const dispatch = useDispatch();
  const questions = useSelector((state: MainState) => state.question.questions);
  const selectedQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, groupItem.path) || groupItem;
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);

  const [anchorVisibility, setAnchorVisibility] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<any>(null);
  const toggleAnchor = () => setAnchorVisibility(false);

  const [isEditingHeader, setIsEditingHeader] = useState(false);

  const handleHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateGroupLabel({ path: groupItem.path, languageCode: defaultLanguage.code, label: e.target.value }));
  };

  const onEditClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(selectQuestion(groupItem));
    dispatch(toggleSettingsTab());
  };

  const openAnchor = (event: SyntheticEvent) => {
    setAnchor(event.currentTarget);
    setAnchorVisibility(true);
  };

  const onDeleteClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    const result = confirm('Are you sure you want to delete the group ?');
    if (result) dispatch(deleteGroup(groupItem));
  };

  const isActive = () => {
    return selectedQuestion.id === groupItem.id;
  };

  const actionIcons = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip title="Delete Group" placement="top" arrow>
          <DeleteOutlined style={{ fontSize: 16, marginTop: '-40%' }} onClick={onDeleteClick} />
        </Tooltip>
        <Tooltip title="Settings" placement="top" arrow>
          <SettingOutlined style={{ fontSize: 15, marginTop: '40%' }} onClick={onEditClick} />
        </Tooltip>
      </div>
    );
  };

  return (
    <StyledBlockWrapper className="block" style={{ border: '0px' }}>
      <Box className={classes.cardStyle}>
        <Collapse
          defaultActiveKey={['1']}
          style={{ backgroundColor: 'white', position: 'relative', borderRadius: '5px' }}
        >
          <Box className={classes.addBoxIconPosition} id="popover-anchor" onClick={openAnchor}>
            <Box className={classes.addBoxIcon}>
              <IconButton
                color="info"
                sx={{
                  // borderBottomLeftRadius: '10px',
                  borderRadius: '5px',
                  color: theme.palette.text.secondary,
                  borderColor: theme.palette.warning.main,
                  background: theme.palette.warning.light,
                  '&:hover ': { background: theme.palette.warning.main },
                }}
                size="small"
                onClick={openAnchor}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-plus"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 5l0 14" />
                  <path d="M5 12l14 0" />
                </svg>
              </IconButton>
            </Box>
          </Box>
          <Panel
            header={
              <Box
                style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditingHeader(!isEditingHeader);
                }}
              >
                {isEditingHeader ? (
                  <TextField
                    size="small"
                    value={groupItem && getLocalizedText(groupItem.label, defaultLanguage.code)}
                    onChange={handleHeaderChange}
                    onBlur={() => setIsEditingHeader(false)}
                    label="Header"
                    autoFocus
                  />
                ) : (
                  <Typography variant="h6">
                    {(groupItem && getLocalizedText(groupItem.label, defaultLanguage.code)) || 'New Group'}
                  </Typography>
                )}
              </Box>
            }
            key="1"
            style={{ backgroundColor: '#f1f5f9' }}
            extra={actionIcons()}
          >
            {isActive() ? <GroupConfig /> : null}
            {children}
          </Panel>
        </Collapse>
        <QuestionAddTool
          open={anchorVisibility}
          anchorEl={anchor}
          toggleAnchor={toggleAnchor}
          currentQuestion={groupItem}
        />
      </Box>
    </StyledBlockWrapper>
  );
}
