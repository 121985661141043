import { fetchCategoryURL, fetchItemURL } from '../../configs/ServerUrl';
import axiosCategoryServices from '../../helpers/axiosCategoryService';
import { notification } from 'antd';
import { _deserailize } from '../FormDesigner/Settings/Options/utils';

export const fetchCategory = async () => {
  const payload = {
    data: {
      type: 'categories',
      attributes: {
        masterdataCategory: {},
        searchString: '',
        updateFlag: false,
      },
    },
  };
  try {
    const response = await axiosCategoryServices.post(`${fetchCategoryURL}`, payload);
    const categories = await _deserailize(response.data);

    notification['success']({
      message: 'Success',
      description: 'Category Fetched !',
    });
    return categories;
  } catch (ex) {
    notification['error']({
      message: 'Error',
      description: 'Cant fetch category',
    });
    return null;
  }
};

export const fetchItems = async (value: any) => {
  const payload = {
    data: {
      type: 'Items',
      attributes: {
        masterData: {
          categoryId: value,
        },
        searchString: '',
        updateFlag: false,
      },
    },
  };
  try {
    const response = await axiosCategoryServices.post(`${fetchItemURL}`, payload);
    const items = await _deserailize(response.data);
    notification['success']({
      message: 'Success',
      description: 'Items Fetched !',
    });
    return items;
  } catch (ex) {
    notification['error']({
      message: 'Error',
      description: 'Cant fetch items',
    });
    return null;
  }
};
