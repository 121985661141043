import React from 'react';
import { useSelector } from 'react-redux';
import { GROUP_QUESTION } from '../../QuesTypes/Constants';
import GroupOptions from './GroupOptions';
import QuestionOptions from './QuestionOptions';
import { IQuestion } from '../../../../types/state';

interface OptionsProps {
  question: IQuestion;
}

export default function Options(props: OptionsProps) {
  const selectedQuestion = useSelector((state: any) => state.question.selectedQuestion);
  return <>{selectedQuestion.type === GROUP_QUESTION ? <GroupOptions {...props} /> : <QuestionOptions {...props} />}</>;
}
