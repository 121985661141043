import { Button, Input, Select } from 'antd';
import Style from '../Style';
import _ from 'lodash';
import React from 'react';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import { _deserailize } from './utils';
import { Autocomplete, Box, Grid, IconButton, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { IOption, IQuestion, MainState } from '../../../../types/state';
import { getAllQuestionsAtPath, getLocalizedText, getQuestionByPath } from '../../../../helpers/utils';
import {
  addChoiceOption,
  removeChoiceOption,
  updateChoiceOptionName,
  updateChoiceOptionValue,
  updateDependencyQuestion,
  updateQuestionChoiceOption,
} from '../../../../redux/QuestionSlice';
import { ListNameMui } from './QuesOptFields';

const { Option } = Select;

interface Props {
  question: IQuestion;
}

export default function Choice({ question }: Props) {
  const dispatch = useDispatch();

  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedQuestion = useSelector((state: MainState) => state.question.selectedQuestion);

  const questions = useSelector((state: MainState) => state.question.questions);
  const currentQuestion = getQuestionByPath(questions, question.path);

  const choiceQuestions = getAllQuestionsAtPath(question.path, questions);

  const classes = Style();

  const addOption = () => {
    const newOption: IOption = {
      option: '',
      value: '',
      uuid: v4(),
    };

    dispatch(addChoiceOption({ path: selectedQuestion.path, option: newOption }));
  };

  const updateOption = (updatedOption: IOption) => {
    dispatch(updateQuestionChoiceOption({ path: currentQuestion.path, newOption: { ...updatedOption } }));
  };

  const handleUpdateDependencyQuestion = (question: IQuestion | null) => {
    dispatch(updateDependencyQuestion({ path: currentQuestion.path, newQuestion: question }));
  };

  return (
    <Grid container minHeight="300px">
      {currentQuestion.choice.isDefault && (
        <Grid container spacing={2}>
          <Grid container item xs={6} spacing={1} mb={4}>
            <Grid item xs={12} mt={2}>
              <ListNameMui question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={choiceQuestions}
                value={currentQuestion.dependencyQuestion}
                onChange={(_, selectedOption) => {
                  handleUpdateDependencyQuestion(selectedOption);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} size="small" label="Dependency Question" />}
              />
            </Grid>
          </Grid>

          {currentQuestion.choice.options &&
            currentQuestion.choice.options.map((data: IOption, index: any) => (
              <React.Fragment key={index}>
                <Box className={classes.conditionSkipLogicForm} sx={{ width: '100%' }}>
                  <Grid container justifyContent="flex-end">
                    <IconButton
                      className={classes.minusOutlinedIcon}
                      onClick={() => {
                        dispatch(
                          removeChoiceOption({
                            path: selectedQuestion.path,
                            optionId: data.uuid,
                          }),
                        );
                      }}
                      color="error"
                    >
                      <CancelIcon />
                    </IconButton>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" sx={{ py: '20px', px: '20px' }}>
                    <Grid container alignItems="start" spacing={2}>
                      <Grid item xs={6}>
                        <Stack gap={1} alignItems="start">
                          <Typography variant="subtitle2">
                            {`Option ( ${defaultLanguage.code} - default ):`}{' '}
                          </Typography>
                          <Input
                            name="option"
                            value={getLocalizedText(data.option, defaultLanguage.code)}
                            onChange={(e) => {
                              dispatch(
                                updateChoiceOptionName({
                                  path: selectedQuestion.path,
                                  optionId: data.uuid,
                                  optionName: e.target.value,
                                  languageCode: defaultLanguage.code,
                                }),
                              );
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack gap={1} alignItems="start">
                          <Typography variant="subtitle2">Value </Typography>
                          <Input
                            name="value"
                            value={data.value}
                            // onChange={(e: any) => optionValueChange(index, e, defaultLanguage.code)}
                            onChange={(e) => {
                              dispatch(
                                updateChoiceOptionValue({
                                  path: selectedQuestion.path,
                                  optionId: data.uuid,
                                  value: e.target.value,
                                }),
                              );
                            }}
                          />
                        </Stack>
                      </Grid>
                      {currentQuestion.dependencyQuestion && (
                        <Grid item xs={12} container>
                          <Grid item xs={12}>
                            <Typography mb={1} variant="subtitle2">
                              Dependency Option
                            </Typography>
                          </Grid>
                          <Grid item xs={12} container spacing={1}>
                            <Grid item xs={6} container>
                              <Grid item xs={12}>
                                <Autocomplete
                                  multiple
                                  options={
                                    currentQuestion.dependencyQuestion &&
                                    currentQuestion.dependencyQuestion.choice &&
                                    currentQuestion.dependencyQuestion.choice.options
                                      ? currentQuestion.dependencyQuestion.choice.options
                                      : []
                                  }
                                  value={data.dependency?.options ? data.dependency?.options : []}
                                  // options={getOptions()}
                                  getOptionLabel={(option: any) => option.option[defaultLanguage.code]}
                                  onChange={(_, selectedOptions) => {
                                    const option = {
                                      ...data,
                                      dependency: {
                                        ...data.dependency,
                                        options: selectedOptions,
                                      },
                                    };
                                    updateOption(option);
                                  }}
                                  renderInput={(params) => <TextField {...params} size="small" label="Options" />}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <br />
              </React.Fragment>
            ))}

          <Grid container>
            <Grid container item justifyContent="right" sx={{ marginRight: '20px', marginTop: '20px' }}>
              <Button type="primary" icon={<PlusOutlined />} size="large" onClick={addOption}>
                Add Choice
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
