import React from 'react';
import { Autocomplete, Chip, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { _deserailize } from '../FormDesigner/Settings/Options/utils';
import { ILanguage, ILanguageData, MainState } from '../../types/state';
import { addSelectedLanguage } from '../../redux/LanguageSlice';

const LanguageSelection = ({ close }: any) => {
  const dispatch = useDispatch();
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const languageData: ILanguageData = useSelector((state: MainState) => state.language);
  const selectedLanguageList = languageData.languageList.filter((language) => language.code !== defaultLanguage.code);

  const handleSelectedLanguage = (newValue: ILanguage[]) => {
    dispatch(addSelectedLanguage(newValue));
  };

  return (
    <Grid container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <Typography>Default Language</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            sx={{ width: '100%' }}
            id="filled-basic"
            label="Default Language"
            variant="outlined"
            value={languageData.defaultLanguage.name}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{ my: 4 }}>
        <Grid item xs={4}>
          <Typography>Select Other Preferred Languages</Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            id="preferred-languages"
            options={selectedLanguageList}
            getOptionLabel={(option: ILanguage) => option.name}
            value={languageData.selectedLanguageList}
            onChange={(event, newValue) => {
              handleSelectedLanguage(newValue);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  {...getTagProps({ index })}
                  key={option.name}
                  label={option.name}
                  color="primary"
                  clickable
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} label="Select a language" variant="outlined" placeholder="Choose languages" />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LanguageSelection;
