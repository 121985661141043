import React, { useEffect, useRef } from 'react';
import Popper from '@material-ui/core/Popper';
import QuestionTypes from '../../features/FormDesigner/QuesTypes';

type QuestionAddToolPropsType = {
  open: boolean;
  anchorEl: HTMLElement | null;
  toggleAnchor: () => void;
  currentQuestion: any; // Adjust `any` to a more specific type if available
};

function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>, toggleAnchor: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        toggleAnchor(); // Directly call the function passed
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, toggleAnchor]); // Include toggleAnchor in the dependency array
}

export default function QuestionAddTool({ open, anchorEl, toggleAnchor, currentQuestion }: QuestionAddToolPropsType) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, toggleAnchor);

  return (
    <Popper
      ref={wrapperRef}
      open={open}
      anchorEl={anchorEl}
      placement="right-start"
      style={{
        width: '70%',
        boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 0px 3px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <div>
        <QuestionTypes close={toggleAnchor} currentQuestion={currentQuestion} />
      </div>
    </Popper>
  );
}
