import { CloseOutlined } from '@ant-design/icons';
import { Box, IconButton } from '@material-ui/core';
import { Form, Input, Typography } from 'antd';
import React from 'react';
import ContactStyle from './Style';
import { updateManualSkipLogic } from '../../../../redux/QuestionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IQuestion, MainState } from '../../../../types/state';

export default function ManuallySkipLogic({ setSkipLogicRender, onEvent }: any) {
  const classes = ContactStyle();
  const dispatch = useDispatch();
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);

  return (
    <>
      <Box className={classes.closeOutlinedIcon}>
        <IconButton
          onClick={() => {
            setSkipLogicRender('');
            dispatch(
              updateManualSkipLogic({
                path: selectedQuestion.path,
                value: '',
              }),
            );
            onEvent('manualSkipLogic', '');
          }}
        >
          <CloseOutlined className={classes.icon} />
        </IconButton>
      </Box>

      <Box className={classes.manualSkipLogic}>
        <Typography.Text className={classes.manualSkipLogicText}>Enter your skip logic code here:</Typography.Text>
        <Input.TextArea
          autoSize={{ minRows: 6, maxRows: 10 }}
          // onChange={(e: any) => onEvent('manualSkipLogic', e.target.value)}
          onChange={(e) =>
            dispatch(
              updateManualSkipLogic({
                path: selectedQuestion.path,
                value: e.target.value,
              }),
            )
          }
        />
      </Box>
    </>
  );
}
