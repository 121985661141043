import { makeStyles, Theme } from '@material-ui/core';

const subHeaderStyle = makeStyles((theme: Theme) => ({
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  formAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '128px',
  },

  formName: {
    // paddingLeft: theme.spacing(2),
    // width: '200%',
  },
  formTitle: {
    paddingLeft: 0,
    fontWeight: 500,
    fontSize: '1.10rem',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgb(241, 245, 249)',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  layoutSettings: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  formTextEditable: {
    outline: 'none',
    border: '1px solid transparent',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    paddingLeft: 10,
    width: '100%',
    '&:hover': {
      border: '1px solid lightgray',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  '@keyframes shake': {
    '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
    '10%': { transform: 'translate(-1px, -2px) rotate(-1deg)' },
    '20%': { transform: 'translate(-3px, 0px) rotate(1deg)' },
    '30%': { transform: 'translate(3px, 2px) rotate(0deg)' },
    '40%': { transform: 'translate(1px, -1px) rotate(1deg)' },
    '50%': { transform: 'translate(-1px, 2px) rotate(-1deg)' },
    '60%': { transform: 'translate(-3px, 1px) rotate(0deg)' },
    '70%': { transform: 'translate(3px, 1px) rotate(-1deg)' },
    '80%': { transform: 'translate(-1px, -1px) rotate(1deg)' },
    '90%': { transform: 'translate(1px, 2px) rotate(0deg)' },
    '100%': { transform: 'translate(1px, -2px) rotate(-1deg)' },
  },
  shake: {
    // animation: `$shake 0.5s ease-in-out`,
    animation: `$shake 0.5s ease-in-out`,
  },
}));

export default subHeaderStyle;
