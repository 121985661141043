import React from 'react';
import { Button, DialogActions, DialogContent, Grid, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { add } from '../../redux/QuestionSlice';
import { createQuestionDefaultValue } from '../FormDesigner/Questions/Constants';
import { getOptions } from '../FormDesigner/QuesTypes/Service';
import { SELECT_ONE } from '../FormDesigner/QuesTypes/Constants';
import { v4 } from 'uuid';

type Option = {
  name: string;
  label: string;
  [key: string]: string | undefined;
};

type CascadingSelectData = {
  [listName: string]: Option[];
};

function parseCascadingSelectData(data: string): CascadingSelectData {
  // Normalize the data: replace multiple spaces or tabs with a tab, and trim each line
  const normalizedData = data
    .split(/\r?\n/) // Split by newlines (handles both \n and \r\n)
    .filter((line) => line.trim() !== '') // Remove empty lines
    .map((line) => line.replace(/ {2,}/g, '\t').trim()) // Replace multiple spaces with tabs, trim the line
    .join('\n'); // Join back into a normalized string

  const lines = normalizedData.split(/\r?\n/); // Split by newlines
  const headers = lines[0].split(/\t| {2,}/); // Adjust to split on tabs or multiple spaces

  const cascadingData: CascadingSelectData = {};

  // Process each row, starting from the second line
  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(/\t| {2,}/); // Adjust to split on tabs or multiple spaces
    const row: { [key: string]: string | undefined } = {};

    // Map headers to their corresponding values
    headers.forEach((header, index) => {
      row[header] = values[index] || undefined;
    });

    const listName = row['list_name'];
    if (!listName) continue; // Skip rows without a list_name

    const option: Option = {
      name: row['name'] || '',
      label: row['label'] || '',
    };

    // Add any additional keys (e.g., country, region) to the option object
    headers.forEach((header) => {
      if (header !== 'list_name' && header !== 'name' && header !== 'label' && row[header]) {
        option[header] = row[header];
      }
    });

    // Initialize the list in the cascadingData if it doesn't exist
    if (!cascadingData[listName]) {
      cascadingData[listName] = [];
    }

    // Add the current option to the corresponding list
    cascadingData[listName].push(option);
  }

  return cascadingData;
}

function createOptions(defaultLanguage: string, selectedLanguage: string, items: { name: string; label: string }[]) {
  return items.map((item) => ({
    option: getOptions(defaultLanguage, selectedLanguage, item.label), // Use label as the display option
    value: item.name, // Use name as the value
    uuid: v4(), // Generate a unique ID for each option
  }));
}

interface Props {
  onCancel?: () => void;
}

const Index = ({ onCancel }: Props) => {
  const dispatch = useDispatch();
  const defaultLanguage = useSelector((state: any) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: any) => state.language.selectedLanguageList);

  const handleCreate = (text: string) => {
    const questions = parseCascadingSelectData(text);
    // const parsedEntries = Object.entries(questions);
    const parsedEntries = Object.entries(questions).reverse();

    parsedEntries.forEach(([key, options]) => {
      // For each key (like 'country', 'region', 'town'), dispatch a select-one question
      dispatch(
        add({
          ...createQuestionDefaultValue(defaultLanguage),
          type: SELECT_ONE,
          label: key,
          name: key, // Dynamically use the key as the question name
          choice: {
            options: createOptions(defaultLanguage, selectedLanguage, options),
            isDefault: true, // Create the choice options
          },
        }),
      );
    });
  };

  function validateCascadingSelectDataWithYup(value: string): boolean | string {
    const data = parseCascadingSelectData(value); // Use the function from before
    if (_.isEmpty(data)) {
      // return errors.join(', '); // Return error message as string
      return 'Paste your formatted table from excel in the box.';
    }
    return true; // No errors, return true
  }

  // Your Yup validation schema
  const CascadingSchema = Yup.object().shape({
    text: Yup.string()
      .required('Cascading text is required')
      .test(
        'is-valid-cascading-select-data', // Test name
        'The cascading select data format is invalid', // Default message
        function (value) {
          if (value) {
            const validationResponse = validateCascadingSelectDataWithYup(value);
            if (typeof validationResponse === 'string') {
              return this.createError({ message: validationResponse }); // Show custom error message
            }
          }
          return true;
        },
      ),
  });

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: CascadingSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const text = values.text;
        handleCreate(text);
        resetForm();
        setSubmitting(false);
        onCancel?.();
      } catch (error) {
        console.error(error);
      }
    },
  });
  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogContent sx={{ p: 0, pt: 1 }}>
          <TextField
            placeholder="Paste your text here"
            multiline
            fullWidth
            rows={10}
            value={values.text}
            onChange={(event) => setFieldValue('text', event.target.value)}
            error={Boolean(touched.text && errors.text)}
            helperText={touched.text && errors.text}
          />
        </DialogContent>
        <DialogActions sx={{ p: 0, mt: 4 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <Stack direction="row" spacing={2} alignItems="center">
                {onCancel !== undefined && (
                  <Button type="button" variant="outlined" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                )}
                <Button type="submit" variant="contained">
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

export default Index;
