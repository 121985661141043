import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { requestRefreshToken } from '../features/User/UserService';
import FormDesigner from '../features/FormDesigner/index';
import { useDispatch, useSelector } from 'react-redux';

import {
  formStylesUrl,
  hxlTagsUrl,
  languageUrl,
  metaDataUrl,
  questionTypesUrl,
  surveyFormByIdUrl,
} from '../configs/ServerUrl';
import axiosServices from '../helpers/axiosService';
import { setFormStylesList, setMetaDataList, setHxlTagsList, setQuestionTypesList, setForm } from '../redux/FormSlice';
import { _deserailize } from '../features/FormDesigner/Settings/Options/utils';
import { addDefaultLanguage, addLanguage, addSelectedLanguage } from '../redux/LanguageSlice';
import { ILanguage, IQuestion, MainState } from '../types/state';
import queryString from 'query-string';
import { Spin } from 'antd';

// import { CircularProgress } from '@mui/material';

function App() {
  // const refreshTokenhandler = async () => await requestRefreshToken();
  const [loading, setLoading] = useState(true);

  const languages: ILanguage[] = useSelector((state: MainState) => state.language.languageList);
  const [selectedLanguageIds, setSelectedLanguageIds] = useState<string[]>([]);
  const urlParams = queryString.parse(window.location.search);
  const surveyId = urlParams.surveyId;

  const dispatch = useDispatch();

  const checkForToken = () => {
    const token = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`boilerplate_token_${process.env.REACT_APP_ENV}=`));
    if (!token) {
      const redirectUrl = `${process.env.REACT_APP_REDIRECT_URL}/?redirectTo=${process.env.REACT_APP_BASE_URL}`;
      window.location.href = redirectUrl;
    } else {
      setLoading(false); // Set loading to false if token is present
    }
  };

  const fetchQuestionTypes = async () => {
    const response = await axiosServices.post(`${questionTypesUrl}`);
    dispatch(setQuestionTypesList({ questionTypes: response.data._value }));
  };

  const fetchMetaData = async () => {
    const response = await axiosServices.post(`${metaDataUrl}`);
    dispatch(setMetaDataList({ metaData: response.data._value }));
  };

  const fetchFormStyles = async () => {
    const response = await axiosServices.post(`${formStylesUrl}`);
    dispatch(setFormStylesList({ formStyles: response.data._value }));
  };

  const fetchHxlTags = async () => {
    const response = await axiosServices.post(`${hxlTagsUrl}`);
    dispatch(setHxlTagsList({ hxlTags: response.data._value }));
  };

  const fetchLanguages = async () => {
    const response = await axiosServices.post(`${languageUrl}`);

    const languageList: ILanguage[] = response.data._value;
    const defaultLanguage = languageList && languageList.find((language) => language.isDefault);

    dispatch(addLanguage(response.data._value));
    dispatch(addDefaultLanguage(defaultLanguage));
  };

  useEffect(() => {
    checkForToken();
    fetchQuestionTypes();
    fetchMetaData();
    fetchFormStyles();
    fetchHxlTags();
    fetchLanguages();
  }, []);

  useEffect(() => {
    if (selectedLanguageIds.length && languages.length) {
      const filteredLanguages = languages.filter((language) => selectedLanguageIds.includes(language.id));
      dispatch(addSelectedLanguage(filteredLanguages));
    }
  }, [languages, selectedLanguageIds]);

  if (loading) {
    // Display a loading indicator or blank screen until token check completes
    return (
      <div>
        <Spin />
      </div>
    );
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact component={FormDesigner} path="/" />
          <Route exact component={FormDesigner} path="/form-designer" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
