import React from 'react';
import { Select, Input, Form, Button } from 'antd';
import { IMatrixChoice, IMatrixColumn, MainState } from '../../types/state';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import MatrixStyle from './Style';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMatrixChoiceOption,
  removeMatrixChoiceOption,
  updateMatrixChoiceOptionLabel,
  updateMatrixChoiceOptionValue,
  updateMatrixColumnLabel,
  updateMatrixColumnResponseType,
  updateMatrixDataColumnSuffix,
} from '../../redux/QuestionSlice';
import { getQuestionByPath } from '../../helpers/utils';
import { v4 as uuidv4 } from 'uuid';

// Define props to accept matrix columns
interface ColumnComponentProps {
  column: IMatrixColumn;
  path: string;
}

const ColumnComponent = ({ column, path }: ColumnComponentProps) => {
  const dispatch = useDispatch();
  const questions = useSelector((state: MainState) => state.question.questions);
  const question = getQuestionByPath(questions, path);
  const updatedColumn = question.matrix?.columns.find((col) => col.id === column.id);

  const matrixClasses = MatrixStyle();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const handleChange = (id: string, field: keyof IMatrixColumn, value: any) => {
    // const newColumnData = {
    //   ...columns.find((column) => column.id === id), // Get the existing column data
    //   [field]: value, // Update the specific field
    // };
    // dispatch(updateMatrixColumn(columnPath, id, newColumnData)); // columnPath should be defined in your component
  };

  const handleAddOption = () => {
    const option: IMatrixChoice = {
      id: uuidv4(),
      label: 'new option',
      dataColumnName: 'option',
    };
    dispatch(addMatrixChoiceOption({ path: path, columnId: column.id, option: option }));
  };

  return (
    <Form {...layout} name="control-hooks">
      <Form.Item label="Response Type" rules={[{ required: false }]}>
        <Select
          onChange={(value) =>
            dispatch(updateMatrixColumnResponseType({ path: path, columnId: column.id, type: value }))
          }
          allowClear
          value={updatedColumn && updatedColumn.responseType}
        >
          <Select.Option disabled value="">
            Select a type
          </Select.Option>
          <Select.Option value="text">Text</Select.Option>
          <Select.Option value="selectOne">Select One</Select.Option>
          <Select.Option value="selectMany">Select Many</Select.Option>
          <Select.Option value="number">Number</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Label" rules={[{ required: false }]}>
        <Input
          value={updatedColumn && updatedColumn.label}
          onChange={(e) =>
            dispatch(updateMatrixColumnLabel({ path: path, columnId: column.id, newLabel: e.target.value }))
          }
        />
      </Form.Item>
      <Form.Item label="Data Column Prefix" rules={[{ required: false }]}>
        <Input
          value={updatedColumn && updatedColumn.dataColumnSuffix}
          onChange={(e) =>
            dispatch(updateMatrixDataColumnSuffix({ path: path, columnId: column.id, suffix: e.target.value }))
          }
        />
      </Form.Item>
      {updatedColumn &&
        updatedColumn.responseType === 'selectOne' &&
        updatedColumn.choices &&
        updatedColumn.choices.map((choice: IMatrixChoice, i: number) => {
          return (
            <div className={matrixClasses.groupFieldInline} key={i}>
              <Form.Item label="Option:" rules={[{ required: true }]}>
                <Input
                  value={choice.label}
                  onChange={(e) =>
                    dispatch(
                      updateMatrixChoiceOptionLabel({
                        path: path,
                        columnId: column.id,
                        optionId: choice.id,
                        newLabel: e.target.value,
                      }),
                    )
                  }
                />
              </Form.Item>
              <Form.Item className={matrixClasses.gapBetweenGroupField} label="Value:" rules={[{ required: true }]}>
                <Input
                  value={choice.dataColumnName}
                  onChange={(e) =>
                    dispatch(
                      updateMatrixChoiceOptionValue({
                        path: path,
                        columnId: column.id,
                        optionId: choice.id,
                        newValue: e.target.value,
                      }),
                    )
                  }
                />
              </Form.Item>
              <DeleteOutlined
                className={matrixClasses.deleteForeverIcon}
                onClick={() =>
                  dispatch(
                    removeMatrixChoiceOption({
                      path: path,
                      columnId: column.id,
                      optionId: choice.id,
                    }),
                  )
                }
              />
            </div>
          );
        })}
      {updatedColumn && updatedColumn.responseType === 'selectOne' && (
        <div className={matrixClasses.addIconCenterBtn}>
          <Button
            type="dashed"
            className={matrixClasses.addIconCenter}
            icon={<PlusOutlined />}
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={handleAddOption}
          >
            Add option
          </Button>
        </div>
      )}
    </Form>
  );
};

export default ColumnComponent;
