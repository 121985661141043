import { IAction } from '../types/common';
import { IForm, IMasterData, IQuestionType } from '../types/state';

/**
 *  Form Reducers
 */
const setForm = (state: any, action: any) => {
  const key = Object.keys(action.payload)[0];
  state[key] = action.payload[key];
};

const setLayoutSettings = (state: any, action: any) => {
  Object.keys(action.payload).forEach((key: string) => {
    state[key] = action.payload[key];
  });
};

const setMasterData = (state: any, action: any) => {
  state.masterData = action.payload;
};

const setMetaDataList = (state: IForm, action: IAction) => {
  const newMetaData = action.payload.metaData;

  state.masterData = {
    ...state.masterData,
    metaDataList: newMetaData,
  };
};

const setFormStylesList = (state: IForm, action: IAction) => {
  const newFormStyleData = action.payload.formStyles;

  state.masterData = {
    ...state.masterData,
    formStyles: newFormStyleData,
  };
};

const setHxlTagsList = (state: IForm, action: IAction) => {
  const newHxlTagsData = action.payload.hxlTags;

  state.masterData = {
    ...state.masterData,
    hxlTags: newHxlTagsData,
  };
};

const setQuestionTypesList = (state: IForm, action: IAction<{ questionTypes: IQuestionType[] }>) => {
  const questionTypesData = action.payload.questionTypes;

  state.masterData = {
    ...state.masterData,
    questionTypes: questionTypesData,
  };
};

export default {
  setForm,
  setLayoutSettings,
  setMasterData,
  setMetaDataList,
  setFormStylesList,
  setHxlTagsList,
  setQuestionTypesList,
};
