import React from 'react';
import { Grid, Paper, Typography, Collapse } from '@mui/material';
import { LabelElement } from '../QuesOptFields';
import { useDispatch, useSelector } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import { MainState } from '../../../../../types/state';
import { getQuestionByPath } from '../../../../../helpers/utils';

const LabelGroup = (props: any) => {
  const dispatch = useDispatch();
  const defaultLanguage = useSelector((state: any) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: any) => state.language.selectedLanguageList);
  const questions = useSelector((state: MainState) => state.question.questions);
  const selectedQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);

  return (
    <Grid container sx={{ p: 2 }}>
      <Paper sx={{ borderTop: 1, borderColor: 'grey.300', width: '100%', mr: '20px', pb: 1 }} elevation={2}>
        {' '}
        <Grid item style={{ color: 'grey' }}>
          <Grid
            container
            alignItems="center"
            direction="row"
            aria-label="settings"
            onClick={() => props.setCollapseLabel(!props.collapseLabel)}
          >
            <Typography style={{ color: 'grey' }} sx={{ fontSize: 15, p: 2 }}>
              Label
            </Typography>
            <CaretDownOutlined />
          </Grid>
          <Collapse in={!props.collapseLabel}>
            <Grid container>
              <Grid item xs={12}>
                <LabelElement
                  question={currentQuestion}
                  path={selectedQuestion.path}
                  onNestedQuestionChange={props.onQuestionObjectChange}
                  code={defaultLanguage.code}
                  isDefault={true}
                  key={defaultLanguage.code}
                  dispatch={dispatch}
                />
              </Grid>
              {selectedLanguage.map((lang: any) => (
                <Grid item xs={12} key={lang.code}>
                  <LabelElement
                    question={currentQuestion}
                    path={selectedQuestion.path}
                    onNestedQuestionChange={props.onQuestionObjectChange}
                    code={lang.code}
                    isDefault={false}
                    dispatch={dispatch}
                  />
                </Grid>
              ))}
            </Grid>
          </Collapse>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default LabelGroup;
