export interface ValidationCriteria {
  condition: string;
  value: string;
  errorMsg?: string;
}

export const validationDefaultValue: ValidationCriteria = {
  condition: '',
  value: '',
};
