/*eslint-disable*/
import { Card, CardContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { getDefaultValueByQuestionType } from './Service';
import Style from '../Questions/Style';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { add } from '../../../redux/QuestionSlice';
import { Col, Row } from 'antd';
import { IQuestionType, MainState } from '../../../types/state';
import { getIconByName } from '../../../helpers/utils';

export default function QuestionTypes(props: any) {
  const classes: any = Style();
  const dispatch = useDispatch();
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedLanguage = useSelector((state: MainState) => state.language.selectedLanguageList);
  const questionTypes = useSelector((state: MainState) => state.form.masterData.questionTypes);

  useEffect(() => {}, [selectedLanguage]);

  const questionBtnClick = (type: string) => {
    dispatch(
      add({
        ...getDefaultValueByQuestionType(type, defaultLanguage, selectedLanguage),
        type,
        path: props.currentQuestion.path,
      }),
    );
    props.close();
  };
  return (
    <Card variant="outlined">
      <CardContent>
        <Row>
          {questionTypes &&
            questionTypes.map((type: IQuestionType, i: number) => {
              return (
                <Col key={i} span={6} style={{ marginTop: 5, marginBottom: 5, cursor: 'pointer' }}>
                  <span key={i} className={classes.iconStyle} onClick={() => questionBtnClick(type.name)}>
                    <img className={classes.iconStyle} width="30" height="25" src={getIconByName(type.icon)} />{' '}
                    <span style={{ paddingLeft: 7 }}></span>
                    {type.title}
                    <p></p>
                  </span>
                </Col>
              );
            })}
        </Row>
      </CardContent>
    </Card>
  );
}
