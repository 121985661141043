import { Form, InputNumber, Button } from 'antd';
import Style from '../Style';
import _ from 'lodash';
import React from 'react';
import { updateRangeItem } from '../../../../redux/QuestionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionByPath } from '../../../../helpers/utils';
import { IQuestion, MainState, ILanguage } from '../../../../types/state';

export default function Range() {
  const classes = Style();
  const dispatch = useDispatch();

  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);

  return (
    <>
      <div className={classes.groupField}>
        {currentQuestion.range && (
          <div className={classes.groupFieldInline}>
            <Form.Item label="Start:" rules={[{ required: true }]}>
              <InputNumber
                name="start"
                value={currentQuestion.range.start}
                min={1}
                max={100}
                // onChange={(e: any) => optionValueChange('start', e)}
                onChange={(e) => dispatch(updateRangeItem({ path: currentQuestion.path, key: 'start', value: e || 1 }))}
              />
            </Form.Item>
            <Form.Item className={classes.gapBetweenGroupField} label="End:" rules={[{ required: true }]}>
              <InputNumber
                name="end"
                value={currentQuestion.range.end}
                min={1}
                max={100}
                // onChange={(e: any) => optionValueChange('end', e)}
                onChange={(e) => dispatch(updateRangeItem({ path: currentQuestion.path, key: 'end', value: e || 1 }))}
              />
            </Form.Item>
            <Form.Item className={classes.gapBetweenGroupField} label="Step:" rules={[{ required: true }]}>
              <InputNumber
                name="step"
                value={currentQuestion.range.step}
                min={1}
                max={100}
                // onChange={(e: any) => optionValueChange('step', e)}
                onChange={(e) => dispatch(updateRangeItem({ path: currentQuestion.path, key: 'step', value: e || 1 }))}
              />
            </Form.Item>
          </div>
        )}
      </div>
    </>
  );
}
