import { ILanguage } from '../../../types/state';
import { createQuestionDefaultValue, questionDefaultValue } from '../Questions/Constants';
import { ListName } from '../Settings/Options/QuesOptFields';
import { MULTIPLE_SELECT, RANKING, SELECT_ONE, QUESTION_MATRIX, RANGE, RATING } from './Constants';
import { v4 } from 'uuid';

export const getOptions = (defaultLanguage: any, selectedLanguage: any, optionValue: any) => {
  const opt: any = {};
  opt[defaultLanguage.code] = optionValue;

  selectedLanguage.map((lang: any) => {
    opt[lang.code] = optionValue;
  });
  return opt;
};

export const getDefaultValueByQuestionType = (
  type: string,
  defaultLanguage: ILanguage,
  selectedLanguage: ILanguage[],
) => {
  switch (type) {
    case MULTIPLE_SELECT:
      return {
        ...createQuestionDefaultValue(defaultLanguage.code),
        list_name: 'quesion_listname',
        choice: {
          options: [
            {
              option: getOptions(defaultLanguage, selectedLanguage, 'option 1'),
              value: 1,
              uuid: v4(),
            },
            {
              option: getOptions(defaultLanguage, selectedLanguage, 'option 2'),
              value: 2,
              uuid: v4(),
            },
          ],
          isDefault: true,
        },
      };

    case SELECT_ONE:
      return {
        ...createQuestionDefaultValue(defaultLanguage.code),
        list_name: 'quesion_listname',
        choice: {
          options: [
            {
              option: getOptions(defaultLanguage, selectedLanguage, 'option 1'),
              value: 1,
              uuid: v4(),
            },
            {
              option: getOptions(defaultLanguage, selectedLanguage, 'option 2'),
              value: 2,
              uuid: v4(),
            },
          ],
          isDefault: true,
        },
      };

    case RANKING:
      return {
        ...createQuestionDefaultValue(defaultLanguage.code),
        ranks: {
          items: [{ id: v4(), label: 'Item to be ranked', value: 'automatic' }],
          choices: [{ id: v4(), label: '1st choice ', value: 'automatic' }],
        },
      };
    case QUESTION_MATRIX:
      return {
        ...createQuestionDefaultValue(defaultLanguage.code),
        matrix: {
          columns: [
            {
              label: 'Column1',
              // dataColumnPrefix: 'column', // Assuming this is a correct prefix for your use case
              id: v4(), // Unique ID
              responseType: '', // Adjust as needed
              required: true,
              choices: [], // Initially no choices
              background: '', // Optional
              expand: 'none', // Optional
              dataColumnSuffix: 'column1', // Add the required dataColumnSuffix
            },
            {
              label: 'Column2',
              // dataColumnPrefix: 'column',
              id: v4(), // Unique ID
              responseType: '', // Adjust as needed
              required: true,
              choices: [], // Initially no choices
              background: '', // Optional
              expand: 'none', // Optional
              dataColumnSuffix: 'column2', // Add the required dataColumnSuffix
            },
          ],
          rows: [
            {
              id: v4(), // Unique ID
              label: 'Row1',
              dataColumnPrefix: 'default Row',
            },
          ],
        },
      };

    case RANGE:
      return {
        ...createQuestionDefaultValue(defaultLanguage.code),
        range: { start: 1, end: 10, step: 1 },
      };
    case RATING:
      return {
        ...createQuestionDefaultValue(defaultLanguage.code),
        ratings: {
          columns: [
            {
              id: v4(),
              title: 'Option',
              subTitle: '',
            },
          ],
          rows: [
            {
              id: v4(),
              title: 'Enter your question',
              subTitle: '',
            },
          ],
        },
      };
    default:
      return createQuestionDefaultValue(defaultLanguage.code);
  }
};
