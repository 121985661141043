import _ from 'lodash';
import { deepClone, getLableByObject, getQuestionByPath, removeLastPathSegment } from '../helpers/utils';
import { createQuestionDefaultValue, questionDefaultValue } from '../features/FormDesigner/Questions/Constants';
import { GROUP_QUESTION } from '../features/FormDesigner/QuesTypes/Constants';
import { travelToNode, getNodeIndex } from './QuestionService';
import { current } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { IQuestion, IQuestionData } from '../types/state';
import { IAction } from '../types/common';
import toast from 'react-hot-toast';

function findShortest(pathList: string[]): number {
  // Map each path to the last element after splitting by ','
  const lastElements = pathList.map((path: string): number => {
    const parts = path.split(',');
    return Number(parts[parts.length - 1]); // Take the last element and convert it to a number
  });

  // Find the smallest element in the lastElements array
  return Math.min(...lastElements);
}

/**
 * Multiselection should be perform in a single group or in a same level.
 * only then group creation would work perfectly
 */
const createGroup = (state: any, action: IAction<{ languageCode: string }>) => {
  if (!state.multiselection.pathList || state.multiselection.pathList.length === 0) {
    toast.error('Path list is empty or undefined');
    return state; // Exit early if no paths are available
  }

  // const group = deepClone(questionDefaultValue);
  const group = deepClone(createQuestionDefaultValue(action.payload.languageCode));
  const questions: IQuestion[] = travelToNode(state.questions, state.multiselection.pathList[0]);
  const removeIndexes = state.multiselection.pathList.map((data: any) => getNodeIndex(data));
  const groupQuestions = _.pullAt(questions, removeIndexes);

  group.name = 'new_group';
  group.type = GROUP_QUESTION;
  group.questions = groupQuestions;
  group.id = uuidv4();

  const moveTo = findShortest(state.multiselection.pathList);

  const parentPath = removeLastPathSegment(state.multiselection.pathList[0]);
  group.path = parentPath ? `${parentPath},${moveTo}` : `${moveTo}`;
  questions.splice(moveTo, 0, group);

  state.multiselection.onGoing = false;
  state.multiselection.pathList = [];
  return state;
};

const updateGroup = (state: any, action: any) => {
  const questions = travelToNode(state.questions, action.payload.path);
  const groupIndex = getNodeIndex(action.payload.path);
  questions.splice(groupIndex, 1, action.payload);
};

const deleteGroup = (state: any, action: any) => {
  const questions = travelToNode(state.questions, action.payload.path);
  const groupIndex = getNodeIndex(action.payload.path);
  questions.splice(groupIndex, 1);
};

const updateGroupLabel = (state: any, action: IAction<{ path: string; languageCode: string; label: string }>) => {
  // const question = (state as IQuestionData).questions.find((ques: IQuestion) => ques.id === action.payload.id);
  // if (question) {
  //   const label = {
  //     [action.payload.languageCode]: action.payload.label,
  //   };
  //   question.label = label;
  // }

  const question = getQuestionByPath(state.questions, action.payload.path);

  if (question) {
    const label = getLableByObject(question.label, action.payload.languageCode);

    const updatedLabel = {
      ...label,
      [action.payload.languageCode]: action.payload.label,
    };
    question.label = updatedLabel;
  }
};

const updateGroupName = (state: any, action: IAction<{ path: string; languageCode: string; name: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);
  if (question) {
    question.name = action.payload.name;
  }
};

const toggleGroupApperance = (state: any, action: IAction<{ path: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);
  // const question = (state as IQuestionData).questions.find((ques: IQuestion) => ques.id === action.payload.id);
  if (question) {
    question.groupAppearance = !question.groupAppearance;
  }
};

const toggleRepeat = (state: any, action: IAction<{ path: string }>) => {
  const question = getQuestionByPath(state.questions, action.payload.path);
  // const question = (state as IQuestionData).questions.find((ques: IQuestion) => ques.id === action.payload.id);
  if (question) {
    question.repeat = !question.repeat;
  }
};

export default {
  createGroup,
  updateGroup,
  deleteGroup,
  updateGroupLabel,
  updateGroupName,
  toggleGroupApperance,
  toggleRepeat,
};
