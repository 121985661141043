import { createSlice } from '@reduxjs/toolkit';
import QuesBankService from './QuesBankService';
import { IQuestion } from '../types/state';

interface IQuesBankState {
  questionList: IQuestion[];
  tags: string[];
}

const initialState: IQuesBankState = {
  questionList: [],
  tags: [],
};

export const quesBankSlice = createSlice({
  name: 'quesBankSlice',
  initialState,
  reducers: {
    ...QuesBankService,
  },
});

export const { setQuestionBankProperties, addToQuesBank } = quesBankSlice.actions;

export default quesBankSlice.reducer;
