import React from 'react';
import { Box, IconButton } from '@mui/material';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch } from 'react-redux';
import { closeSettingsTab } from '../redux/QuestionSlice';

interface SettingsLayoutProps {
  children: React.ReactNode;
}

export default function SettingsLayout({ children }: SettingsLayoutProps) {
  const dispatch = useDispatch();
  const handleCloseClick = () => {
    dispatch(closeSettingsTab());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // height: '100vh',
        position: 'relative',
        padding: 2,
      }}
    >
      <IconButton
        onClick={handleCloseClick}
        // color="primary"
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 1000,
        }}
      >
        <CancelIcon />
      </IconButton>
      <Box
        sx={{
          marginTop: '40px',
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
