import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import QuestionAddTool from '../../../components/Poper';
import { questionDefaultValue } from './Constants';
import Style from './Style';
import { IconButton, Skeleton } from '@mui/material';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { surveyFormByIdUrl } from '../../../configs/ServerUrl';
import axiosServices from '../../../helpers/axiosService';
import { setForm } from '../../../redux/FormSlice';
import { setQuestions } from '../../../redux/QuestionSlice';
import { ISurveyByIdResponse } from '../../../types/common';
import { ILanguage, MainState, IQuestion } from '../../../types/state';
import { addSelectedLanguage } from '../../../redux/LanguageSlice';

export default function InitialQuestion() {
  const dispatch = useDispatch();
  const languages: ILanguage[] = useSelector((state: MainState) => state.language.languageList);

  const [loading, setLoading] = useState(false);
  const [selectedLanguageIds, setSelectedLanguageIds] = useState<string[]>([]);
  const urlParams = queryString.parse(window.location.search);
  const surveyId = urlParams.surveyId;
  const [anchor, setAnchor] = useState<any>(null);
  const [anchorVisibility, setAnchorVisibility] = useState<boolean>(false);
  const classes = Style();
  const toggleAnchor = () => setAnchorVisibility(false);

  const openAnchor = (event: SyntheticEvent) => {
    setAnchor(event.currentTarget);
    setAnchorVisibility(!anchorVisibility);
  };

  const fetchSurveyById = async (id: string) => {
    setLoading(true);
    const response = await axiosServices.get(`${surveyFormByIdUrl}/${id}`);

    const responseData: ISurveyByIdResponse = response.data._value;

    const formTitle = responseData.title;
    const formName = responseData.idString;
    const questions: IQuestion[] = responseData.formJson;
    setSelectedLanguageIds(responseData.selectedLanguages);

    dispatch(setQuestions({ questions: questions }));
    dispatch(setForm({ [`formName`]: formName }));
    dispatch(setForm({ [`formTitle`]: formTitle }));
    setLoading(false);
  };

  useEffect(() => {
    if (surveyId) {
      fetchSurveyById(surveyId as string);
    }
  }, []);

  useEffect(() => {
    if (selectedLanguageIds.length && languages.length) {
      const filteredLanguages = languages.filter((language) => selectedLanguageIds.includes(language.id));
      dispatch(addSelectedLanguage(filteredLanguages));
    }
  }, [languages, selectedLanguageIds]);

  return (
    <Card className={classes.cardRelative} style={{ borderRadius: '5px' }}>
      <div style={{ textAlign: 'center' }}>
        {loading ? (
          // <p>Loading...</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* Skeleton Loader for loading animation */}
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="rectangular" width="100%" height={60} style={{ marginTop: 16 }} />
            <Spin size="large" style={{ marginTop: 16 }} />
          </div>
        ) : (
          <>
            <p> This form is currently empty </p>
            <p> You can add questions, notes, prompts, or other fields by clicking on the + sign below </p>
          </>
        )}
      </div>
      {/* <PlusOutlined onClick={openAnchor} /> */}
      <IconButton aria-label="Add" color={anchorVisibility ? 'warning' : 'primary'} onClick={openAnchor}>
        {anchorVisibility ? <IndeterminateCheckBoxIcon fontSize="large" /> : <AddBoxIcon fontSize="large" />}
      </IconButton>
      <QuestionAddTool
        open={anchorVisibility}
        anchorEl={anchor}
        toggleAnchor={toggleAnchor}
        currentQuestion={questionDefaultValue}
      />
    </Card>
  );
}
