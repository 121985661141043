import { createSlice } from '@reduxjs/toolkit';
import FormService from './FormService';

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    formName: '',
    formTitle: '',
    formStyle: 'default_single_page',
    metaData: ['start time', 'end time'],
    masterData: {
      questionTypes: [],
      metaDataList: [],
      formStyles: [],
      hxlTags: [],
    },
  },
  reducers: {
    ...FormService,
  },
});

export const {
  setForm,
  setLayoutSettings,
  setMasterData,
  setMetaDataList,
  setFormStylesList,
  setHxlTagsList,
  setQuestionTypesList,
} = formSlice.actions;

export default formSlice.reducer;
