import React from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { HeaderTitle } from '../../constants';
import { Box, Grid } from '@material-ui/core';
import ContactStyle from '../../Style';
import _ from 'lodash';
import { v4 as uuid4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { IQuestion, IRankItem, MainState } from '../../../../types/state';
import { string } from 'yup';
import {
  addRankingChoice,
  addRankingItem,
  removeRankingChoice,
  removeRankingItem,
  updateRankingChoiceLabel,
  updateRankingChoiceValue,
  updateRankingItemLabel,
  updateRankingItemValue,
} from '../../../../redux/QuestionSlice';
import { getOrdinalSuffix, getQuestionByPath } from '../../../../helpers/utils';

export default function RankingOption() {
  const dispatch = useDispatch();
  const classes = ContactStyle();

  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);

  const onChoiceChange = (index: number, e: any, property: string) => {
    // const tempOptions = _.cloneDeep(currentQuestion.ranks.choices);
    // let opt = tempOptions[index];
    // opt = { ...opt, [property]: e };
    // tempOptions[index] = opt;
    // props.onChange({ ...currentQuestion, ranks: { ...currentQuestion.ranks, choices: tempOptions } });
  };

  const onItemChange = (index: number, e: any, property: string) => {
    // const tempOptions = _.cloneDeep(currentQuestion.ranks.items);
    // let opt = tempOptions[index];
    // opt = { ...opt, [property]: e };
    // tempOptions[index] = opt;
    // props.onChange({ ...currentQuestion, ranks: { ...props.question.ranks, items: tempOptions } });
  };

  const addChoice = () => {
    const existingChoices = currentQuestion.ranks?.choices || [];

    // Generate the new label based on the number of existing choices
    const newLabel = `${existingChoices.length + 1}${getOrdinalSuffix(existingChoices.length + 1)} choice`;
    const choice: IRankItem = {
      id: uuid4(),
      label: newLabel,
      value: 'automatic',
    };
    dispatch(addRankingChoice({ path: currentQuestion.path, choice }));
  };

  const removeChoice = (choiceId: string) => {
    dispatch(removeRankingChoice({ path: currentQuestion.path, choiceId }));
  };

  const addItem = () => {
    const item: IRankItem = {
      id: uuid4(),
      label: 'Item to be ranked',
      value: 'automatic',
    };

    dispatch(addRankingItem({ path: currentQuestion.path, item }));
  };

  const removeItem = (itemId: string) => {
    dispatch(removeRankingItem({ path: currentQuestion.path, itemId }));
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 15, width: '100%' }}>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          {currentQuestion.ranks?.choices &&
            currentQuestion.ranks.choices.map((choice: IRankItem, key: number) => {
              return (
                <Grid item xs={12} style={{ border: '1px solid lightgray', position: 'relative' }} key={choice.id}>
                  <Box>
                    <HeaderTitle
                      dataIndex={choice.id}
                      title={choice.label}
                      //   onHeaderChange={(i: any, label: any) => onChoiceChange(i, label, 'label')}
                      //   onHeaderChange={onChoiceChange}
                      onHeaderChange={(i: number, value: string) =>
                        dispatch(
                          updateRankingChoiceLabel({ path: currentQuestion.path, choiceId: choice.id, label: value }),
                        )
                      }
                    />
                    <HeaderTitle
                      dataIndex={choice.id}
                      title={choice?.value}
                      //   onHeaderChange={(i: any, value: any) => onChoiceChange(i, value, 'value')}
                      onHeaderChange={(i: number, value: string) =>
                        dispatch(updateRankingChoiceValue({ path: currentQuestion.path, choiceId: choice.id, value }))
                      }
                    />
                  </Box>
                  <Box
                    className={classes.deleteIconPosition}
                    id="popover-anchor1"
                    onClick={() => removeChoice(choice.id)}
                  >
                    <DeleteOutlined className={classes.deleteIcon} />
                  </Box>
                </Grid>
              );
            })}
          <Grid item xs={12}>
            {' '}
            <Button onClick={addChoice}> Add Choice </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid container spacing={1}>
          {currentQuestion.ranks?.items &&
            currentQuestion.ranks.items.map((item: IRankItem, i: number) => {
              return (
                <Grid item xs={12} style={{ border: '1px solid lightgray', position: 'relative' }} key={i}>
                  <Box>
                    <HeaderTitle
                      dataIndex={item.id}
                      title={item.label}
                      //   onHeaderChange={(i: any, label: any) => onItemChange(i, label, 'label')}
                      onHeaderChange={(i: number, value: string) =>
                        dispatch(updateRankingItemLabel({ path: currentQuestion.path, itemId: item.id, label: value }))
                      }
                    />
                    <HeaderTitle
                      itemIndex={item.id}
                      title={item?.value}
                      //   onHeaderChange={(i: any, value: any) => onItemChange(i, value, 'value')}
                      onHeaderChange={(i: number, value: string) =>
                        dispatch(updateRankingItemValue({ path: currentQuestion.path, itemId: item.id, value }))
                      }
                    />
                  </Box>
                  <Box className={classes.deleteIconPosition} id="popover-anchor1" onClick={() => removeItem(item.id)}>
                    <DeleteOutlined className={classes.deleteIcon} />
                  </Box>
                </Grid>
              );
            })}
          <Grid item xs={12}>
            {' '}
            <Button onClick={addItem}> Add Item </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
