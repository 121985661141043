import _ from 'lodash';
import { IQuestion } from '../types/state';

/**
 *  QuesBank Reducers
 */
const setQuestionBankProperties = (state: any, action: any) => {
  state[action.payload.key] = action.payload.value;
};

const addToQuesBank = (state: any, action: any) => {
  const questionBank: IQuestion[] = state.questionList;
  const exist = questionBank.find((question) => question.id === action.payload.id);
  if (!exist) {
    state.questionList = [..._.cloneDeep(state.questionList), action.payload];
  }
};

export default { setQuestionBankProperties, addToQuesBank };
