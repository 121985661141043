import axios from 'axios';

import { refreshTokenURL } from '../configs/ServerUrl';
import jwt_decode from 'jwt-decode';

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() || null;
  }

  return null;
};

const axiosCategoryServices = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// TODO
//  need to implement refresh token. currently server don't have any refresh token api
axiosCategoryServices.interceptors.request.use();

// interceptor for http
axiosCategoryServices.interceptors.request.use((request) => {
  // const urlParams = queryString.parse(window.location.search);
  // const token = urlParams.token;
  const token = getCookie('boilerplate_token');
  if (token) {
    request.headers!.Authorization = `Bearer ${token}`;
  }
  return request;
});

// refresh token
axiosCategoryServices.interceptors.request.use(
  async (config) => {
    // const token = localStorage.getItem('token');
    const token = getCookie('boiler_token');
    try {
      if (token) {
        const jwt: any = jwt_decode(token);
        if (jwt.exp < Math.floor(new Date().getTime() / 1000)) {
          const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/${refreshTokenURL}`, {
            refreshToken: localStorage.getItem('refreshToken'),
          });
          if (res.data) {
            localStorage.setItem('token', res.data.refresh_token.token);
          }
        }
      }
    } catch (e) {
      localStorage.removeItem('token');
    }
    return config;
  },
  (err) => {
    console.log('error in getting ', err);
  },
);

export default axiosCategoryServices;
