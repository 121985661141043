import React from 'react';
import Style from '../Style';
import { Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IAppearance, IQuestion, MainState } from '../../../../types/state';
import {
  NameElement,
  LabelElement,
  CalculationElement,
  GuidenceHintElement,
  MandatoryResponse,
  DefaultResponse,
  Appearance,
  HXLElement,
  Parameters,
  SeedElement,
  ListName,
  AcceptedFileElement,
  KoboScoreChoices,
  MatrixAppearance,
  Repeat,
} from './QuesOptFields';
import { getQuestionByPath } from '../../../../helpers/utils';
import { selectQuestion } from '../../../../redux/QuestionSlice';

interface QuestionOptionsProps {
  question: IQuestion; // The question data the group option relates to
}

export default function QuestionOptions({ question }: QuestionOptionsProps) {
  const dispatch = useDispatch();
  const defaultLanguage = useSelector((state: any) => state.language.defaultLanguage);
  const questions = useSelector((state: MainState) => state.question.questions);
  const selectedQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, question.path) || question;

  const classes = Style();

  return (
    <>
      <div className={classes.quesOptionSection}>
        <Row justify="start">
          <NameElement question={question} path={selectedQuestion.path} dispatch={dispatch} />

          {/* Current question doesnt have the rigth path, thats why it passing from the selectedQuestion */}
          <LabelElement
            question={question}
            path={selectedQuestion.path}
            code={defaultLanguage.code}
            isDefault={true}
            dispatch={dispatch}
          />

          <CalculationElement question={question} />

          <GuidenceHintElement
            question={question}
            path={selectedQuestion.path}
            code={defaultLanguage.code}
            isDefault={true}
            dispatch={dispatch}
          />

          <MandatoryResponse question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />

          <DefaultResponse question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />

          <Appearance question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />

          <HXLElement question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />

          <Parameters question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />

          <SeedElement question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />

          {/* <ListName question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} /> */}

          <AcceptedFileElement question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />

          <KoboScoreChoices question={currentQuestion} path={selectedQuestion.path} dispatch={dispatch} />

          <MatrixAppearance question={currentQuestion} />
          <Repeat question={currentQuestion} />
        </Row>
      </div>
    </>
  );
}
