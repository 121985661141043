import { PlusOutlined } from '@ant-design/icons';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import ContactStyle from '../../Style';
import { v4 as uuid4 } from 'uuid';
import MatrixTable from '../../../../components/MatrixTable';
import { ILanguage, IMatrixChoice, IMatrixColumn, IMatrixRow, IQuestion, MainState } from '../../../../types/state';
import { useDispatch, useSelector } from 'react-redux';
import { addMatrixColumn, addMatrixRow, removeMatrixColumn, removeMatrixRow } from '../../../../redux/QuestionSlice';
import { getQuestionByPath } from '../../../../helpers/utils';

export default function QuestionMatrix() {
  const classes = ContactStyle();
  const dispatch = useDispatch();

  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);

  const defaultLanguage: ILanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);

  // const mainQuestion = questions.find((question) => question.id === questionItem.id);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);

  const onClickAddColumn = () => {
    const newColumn: IMatrixColumn = {
      label: 'Column',
      dataColumnSuffix: 'column',
      id: `${uuid4()}`,
      required: false,
      choices: [],
      expand: 'none',
    };
    dispatch(addMatrixColumn({ path: currentQuestion.path, column: newColumn }));
  };
  const onClickDeleteColumn = (id: string) => {
    dispatch(removeMatrixColumn({ path: currentQuestion.path, columnId: id }));
  };

  const onClickAddRow = () => {
    const newRow = {
      id: `${uuid4()}`,
      label: 'Row',
      dataColumnPrefix: 'default Row',
    };
    dispatch(addMatrixRow({ path: currentQuestion.path, row: newRow }));
    // copyRows.push(newColumn);
    // onSettingsChange({ ...question, matrix: { ...question.matrix, rows: copyRows } });
  };

  return (
    <div>
      <div style={{ width: '100%', padding: 20 }}>
        <div style={{ paddingRight: 25, position: 'relative' }}>
          <MatrixTable question={currentQuestion} deleteColumn={(value) => onClickDeleteColumn(value)} />
          {/* <Table pagination={false} dataSource={formattingDataSource(question.ratings?.rows)} columns={formattingColumns(question.ratings?.columns)} className={classes.table} /> */}
          <Box className={classes.addIconPosition} id="popover-anchor" onClick={onClickAddColumn}>
            <PlusOutlined />
          </Box>
          <Box className={classes.addRowIconPosition} id="popover-anchor" onClick={onClickAddRow}>
            <PlusOutlined /> Add Row
          </Box>
        </div>
      </div>
    </div>
  );
}
