// import React from 'react';
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
// import { IMatrixColumn } from '../../types/state';
// import ColumnComponent from './Column';

// interface MatrixDialogProps {
//   open: boolean;
//   onClose: () => void;
//   column: IMatrixColumn;
// }

// const MatrixDialog = ({ open, onClose, column }: MatrixDialogProps) => {
//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
//       <DialogTitle>Matrix Columns</DialogTitle>
//       <DialogContent>
//         <ColumnComponent column={column} />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} color="primary">
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default MatrixDialog;

import React from 'react';
import { Modal, Button } from 'antd';
import { IMatrixColumn } from '../../types/state';
import ColumnComponent from './Column';

interface MatrixDialogProps {
  open: boolean;
  onClose: () => void;
  column: IMatrixColumn;
  path: string;
}

const MatrixDialog = ({ open, onClose, column, path }: MatrixDialogProps) => {
  return (
    <Modal
      title="Matrix Columns"
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose} type="primary">
          Close
        </Button>,
      ]}
      width={800} // Set width as needed
    >
      <ColumnComponent column={column} path={path} />
    </Modal>
  );
};

export default MatrixDialog;
