import React, { useState } from 'react';
import _ from 'lodash';
import { Grid, Typography } from '@mui/material/';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import LabelGroup from './LabelGroup';
import GuidanceHintGroup from './HintGroup';
import ErrorGroup from './ErrorGroup ';
import ChoiceGroup from './ChoiceGroup';

import { MULTIPLE_SELECT, SELECT_ONE } from '../../../QuesTypes/Constants';

export default function Translate(props: any) {
  const [collapse, setCollapse] = useState<any>(true);
  const [collapseLabel, setCollapseLabel] = useState<any>(false);
  const [collapseHint, setCollapseHint] = useState<any>(true);
  const [collapseError, setCollapseError] = useState<any>(true);
  const [collapseChoice, setCollapseChoice] = useState<any>(true);

  const onQuestionObjectChange = (e: any, parent: any, code: any) => {
    console.log('parent', parent, code, e.target.value, props.question);
    props.onChange({ ...props.question, [parent]: { ...props.question[parent], [code]: e.target.value } });
  };

  const optionValueChange = (index: number, e: any, code: any) => {
    const tempOptions = _.cloneDeep(props.question.choice);
    let opt = tempOptions.options[index];
    if (e.target.name === 'value') {
      opt = { ...opt, [e.target.name]: e.target.value };
      tempOptions.options[index] = opt;
      console.log({ tempOptions });
      props.onChange({ ...props.question, choice: tempOptions });
    } else if (e.target.name === 'option') {
      console.log(e.target.name, code, e.target.value, opt[e.target.name], opt);
      opt = { ...opt, [e.target.name]: { ...opt[e.target.name], [code]: e.target.value } };
      tempOptions.options[index] = opt;
      console.log({ tempOptions });
      props.onChange({ ...props.question, choice: tempOptions });
    }
  };

  return (
    <>
      <Grid container>
        {collapse && (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            aria-label="settings"
            sx={{ margin: '20px' }}
            onClick={() => {
              setCollapseLabel(false);
              setCollapseHint(false);
              setCollapseChoice(false);
              setCollapseError(false);
              setCollapse(!collapse);
            }}
          >
            <Typography style={{ color: 'grey' }}>Expand All</Typography>
            <CaretDownOutlined />
          </Grid>
        )}
        {!collapse && (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            aria-label="settings"
            sx={{ margin: '20px' }}
            onClick={() => {
              setCollapseLabel(true);
              setCollapseHint(true);
              setCollapseChoice(true);
              setCollapseError(true);
              setCollapse(!collapse);
            }}
          >
            <Typography style={{ color: 'grey' }}>Collapse All</Typography>
            <CaretUpOutlined />
          </Grid>
        )}
      </Grid>{' '}
      <LabelGroup
        {...props}
        collapseLabel={collapseLabel}
        setCollapseLabel={setCollapseLabel}
        onQuestionObjectChange={onQuestionObjectChange}
      />
      <GuidanceHintGroup
        {...props}
        collapseHint={collapseHint}
        setCollapseHint={setCollapseHint}
        onQuestionObjectChange={onQuestionObjectChange}
      />
      {/* <ErrorGroup
        {...props}
        collapseError={collapseError}
        setCollapseError={setCollapseError}
        onQuestionObjectChange={onQuestionObjectChange}
      /> */}
      {(props.question.type === SELECT_ONE || props.question.type === MULTIPLE_SELECT) && (
        <ChoiceGroup
          {...props}
          collapseChoice={collapseChoice}
          setCollapseChoice={setCollapseChoice}
          onQuestionObjectChange={onQuestionObjectChange}
          optionValueChange={optionValueChange}
        />
      )}
    </>
  );
}
