import React, { useState } from 'react';
import { Form, Select, AutoComplete } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutSettings } from '../../redux/FormSlice';
const { Option } = Select;

const QuestionBank = ({ close }: any) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<any>();
  const { questionList, tags } = useSelector((state: any) => state.questionBank);

  const onFinish = (values: any) => {
    dispatch(setLayoutSettings(values));
  };

  const onSelect = (data: any) => {
    console.log('onSelect', data);
  };
  const onChange = (data: any) => {
    setSearch(data);
  };

  const formatQuestions = () => {
    return questionList.map((ques: any, index: any) => ({ value: ques.name, label: ques.label.EN }));
  };

  return (
    <Form name="validate_other" layout="vertical" onFinish={onFinish}>
      <Form.Item>
        <AutoComplete
          backfill={true}
          value={search}
          options={formatQuestions()}
          style={{
            width: '100%',
          }}
          onSelect={onSelect}
          onChange={onChange}
          filterOption={(inputValue: any, option: any) =>
            option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          placeholder="control mode"
        />
      </Form.Item>
      <Form.Item>
        <Select placeholder="Please select your preferred languages">
          {tags.map((tag: any, index: any) => (
            <Option value={tag.value} key={index}>
              {tag.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default QuestionBank;
